<template>
  <b-label>
    <template #default="{ props }">
      <div v-bind="props" class="d-flex align-center justify-space-between">
        <span>АЦСК</span>
        <div class="d-flex align-center gap-3">
          <span>Визначати автоматично</span>
          <v-switch
            v-model="isAutoCaId"
            color="secondary"
            hide-details></v-switch>
        </div>
      </div>
    </template>
  </b-label>
  <div @click="$directory.fill('caList')">
    <b-select
      v-model="caId"
      :items="$directory.get('caList')"
      :item-title="ca => ca.issuerCNs[0]"
      :loading="$loading.isLoading('caList')"
      :placeholder="isAutoCaId ? 'визначати автоматично' : 'оберіть зі списку'"
      @update:model-value="isAutoCaId = false"></b-select>
  </div>
</template>

<script lang="ts">
import { BLabel } from 'best-modules/components'
import { computed, ref, watch } from 'vue'
import { fillDirectory, getDirectory } from '@/directory'

export default {
  name: 'CaSelect',
  emits: ['update:model-value'],
  components: { BLabel },
  props: {
    modelValue: {
      type: Number,
    },
  },
  setup(props, { emit }) {
    const caId = computed<number | null>({
      get() {
        return props.modelValue
      },
      set(val) {
        emit('update:model-value', val)
      },
    })

    const isAutoCaId = ref<boolean>(true)

    watch(isAutoCaId, async value => {
      if (value) {
        caId.value = null
      } else {
        await fillDirectory('caList')
        if (!caId.value) {
          caId.value = getDirectory('caList')[0].id as number
        }
      }
    })

    return { caId, isAutoCaId }
  },
}
</script>

<style scoped lang="scss"></style>

<template>
  <div>
    <div class="d-flex align-center justify-space-between mb-10">
      <h2>Вітаємо! 👋</h2>
      <span class="link">
        <router-link :to="{ name: 'reset-password' }">
          Забули пароль?
        </router-link>
      </span>
    </div>
    <b-form :submit="submit" hideActionButtons>
      <b-input-phone
        v-model="credentials.phone"
        label="Телефон"
        :error-messages="getErrorMessages(v$.phone)"
        @blur="v$.phone.$touch()" />
      <b-input-password
        v-model="credentials.password"
        label="Пароль"
        :error-messages="getErrorMessages(v$.password)"
        @blur="v$.password.$touch()" />
      <v-btn
        :loading="$loading.isLoading('actionHandler')"
        type="submit"
        size="large"
        class="mt-3 w-100">
        Підтвердити
        <v-icon class="ml-1">{{ 'mdi-arrow-right-thin' }}</v-icon>
      </v-btn>
    </b-form>
  </div>
</template>

<script lang="ts">
import { BForm, BInputPhone, BInputPassword } from 'best-modules/components'
import { getErrorMessages } from 'best-modules/utils'
import { reactive } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import { useAuth } from '@/store/auth/auth'

export default {
  name: 'Login',
  components: { BForm, BInputPhone, BInputPassword },
  methods: { getErrorMessages },
  setup() {
    const { getQr } = useAuth()

    const credentials = reactive({
      phone: null,
      password: null,
    })
    const rules = {
      phone: { required, minLength: minLength(23) },
      password: { required, minLength: minLength(5) },
    }

    const v$ = useVuelidate(rules, credentials)

    const submit = () => {
      v$.value.$validate()
      if (!v$.value.$invalid) {
        return getQr(credentials).catch(() => {
          console.log('unauthorized')
        })
      }
    }

    return {
      credentials,
      v$,
      submit,
    }
  },
}
</script>

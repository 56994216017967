<template>
  <div>
    <div class="mb-1">
      <div class="text-success d-flex align-center gap-1">
        <v-icon size="small">mdi-check</v-icon>
        Підписано
      </div>
    </div>
    <div>
      <div class="font-weight-bold text-truncate">
        <span>{{
          signature.certificate?.ownerOrganization ||
          signature.certificate.subjFullName
        }}</span>
      </div>
      <div class="font-weight-bold">
        {{
          signature.certificate?.edrpou ||
          signature.certificate?.inn ||
          signature.certificate?.subjEDRPOUCode
        }}
      </div>

      <div class="mt-2">
        <div>
          {{ new CustomDate(signature?.signingDate).toString({ time: true }) }}
        </div>
        <div>Електронний підпис</div>
        <div class="text-wrap">
          Власник ключа:
          {{
            signature.certificate.ownerFullName ||
            signature.certificate.subjFullName
          }}
        </div>
        <div class="text-wrap">
          АЦСК:
          {{
            signature.certificate?.issuer ||
            signature.certificate?.issuerCN ||
            signature.fullCertificate?.issuerCN
          }}
        </div>
        <div class="text-wrap">
          Організація (установа):
          {{
            signature.certificate.subjOrg || signature.fullCertificate.subjOrg
          }}
        </div>
        <div class="text-wrap">
          Посада:
          {{
            signature.certificate.subjTitle ||
            signature.fullCertificate.subjTitle
          }}
        </div>
        <div class="text-wrap">
          Серійний номер:
          {{ signature.certificate.serial || signature.fullCertificate.serial }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { CustomDate } from 'best-modules/utils'

export default {
  name: 'Signature',
  props: {
    signature: {
      type: Object,
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      CustomDate,
    }
  },
}
</script>

<style scoped lang="scss"></style>

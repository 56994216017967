import { getDirectory } from '@/directory'

class Period {
  month?: number
  year?: number
  constructor(period?: string) {
    if (
      period &&
      typeof period === 'string' &&
      period.length === 7 &&
      period.includes('-')
    ) {
      const [year, month] = period.split('-')
      const isStringLikeNumber = (str: any): boolean => {
        return typeof str === 'string' && !Number.isNaN(Number(str))
      }

      if (isStringLikeNumber(year) && year.length === 4) {
        this.year = Number(year)
      }
      if (
        isStringLikeNumber(month) &&
        [1, 2].includes(month.length) &&
        Number(month) <= 31
      ) {
        this.month = Number(month)
      }
    }
  }

  toString(type: 'date' | 'period' = 'date'): string | null {
    if (this.year && this.month) {
      const period = `${this.year}-${String(this.month).padStart(2, '0')}`
      if (type === 'date') {
        return `${period}-01`
      }
      if (type === 'period') {
        return period
      }
    } else {
      return null
    }
  }
  getMonth(type: 'num' | 'text' = 'text'): string | null {
    if (this.month) {
      switch (type) {
        case 'num':
          return String(this.month).padStart(2, '0')
        case 'text':
          return getDirectory('months', null, { type: 'collection' }).getItem(
            this.month
          ).name
      }
    }
  }
}

export { Period }

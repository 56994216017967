<script setup lang="ts"></script>

<template>
  <div class="cursor-pointer d-flex align-center">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none">
      <path
        d="M20.165 0.975098H3.61418C2.99762 0.975098 2.4978 1.47492 2.4978 2.09148V20.1574C2.4978 20.774 2.99762 21.2738 3.61418 21.2738H20.165C20.7816 21.2738 21.2814 20.774 21.2814 20.1574V2.09148C21.2814 1.47492 20.7816 0.975098 20.165 0.975098Z"
        fill="#514EF3" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.61463 0.531738H20.1566C20.5907 0.531738 20.9806 0.708946 21.2641 0.992472C21.5476 1.276 21.7249 1.66585 21.7249 2.09999V20.157C21.7249 20.5912 21.5476 20.981 21.2641 21.2646C20.9806 21.5481 20.5907 21.7253 20.1566 21.7253H3.61463C3.18048 21.7253 2.79063 21.5481 2.50711 21.2646C2.22358 20.981 2.04639 20.5912 2.04639 20.157V2.09999C2.04639 1.66585 2.22358 1.276 2.50711 0.992472C2.79063 0.708946 3.18048 0.531738 3.61463 0.531738ZM20.1566 1.42662H3.61463C3.42857 1.42662 3.26023 1.50636 3.13619 1.62154C3.01214 1.74559 2.94126 1.91393 2.94126 2.09999V20.157C2.94126 20.3431 3.021 20.5115 3.13619 20.6355C3.26023 20.7595 3.42857 20.8304 3.61463 20.8304H20.1566C20.3427 20.8304 20.511 20.7507 20.635 20.6355C20.7591 20.5115 20.83 20.3431 20.83 20.157V2.09999C20.83 1.91393 20.7502 1.74559 20.635 1.62154C20.511 1.4975 20.3427 1.42662 20.1566 1.42662Z"
        fill="#014772" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.5936 21.2736H5.08496V7.40739C5.08496 6.78718 5.59 6.29102 6.20135 6.29102H7.46835C8.08857 6.29102 8.58473 6.79604 8.58473 7.40739V21.2736H8.5936Z"
        fill="white" />
      <path
        d="M11.2521 4.4834H12.5191C13.1393 4.4834 13.6355 4.98843 13.6355 5.59978V18.1547C13.6355 18.7749 13.1305 19.271 12.5191 19.271H11.2521C10.6319 19.271 10.1357 18.766 10.1357 18.1547V5.59978C10.1357 4.97957 10.6408 4.4834 11.2521 4.4834Z"
        fill="white" />
      <path
        d="M16.1867 3.53516H17.4537C18.0739 3.53516 18.5701 4.04019 18.5701 4.65154V17.2064C18.5701 17.8266 18.0651 18.3228 17.4537 18.3228H16.1867C15.5665 18.3228 15.0703 17.8178 15.0703 17.2064V4.65154C15.0703 4.03133 15.5753 3.53516 16.1867 3.53516Z"
        fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.12584 14.327C-0.213244 16.7104 0.380393 22.1949 5.7851 20.7507C11.1898 19.3064 17.2679 14.1232 17.2679 14.1232L15.1149 11.9702H22.6283V19.0052L21.0157 17.3926C21.0157 17.3926 14.0428 24.153 7.45081 23.772C0.858833 23.391 -1.43595 18.1812 2.1347 14.327H2.12584Z"
        fill="#F0CA0C" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.859194 17.0559C0.584528 19.3418 1.95786 21.672 5.57282 20.7949C5.80318 20.7594 6.05127 20.7063 6.30822 20.6354C10.8358 19.4216 15.8506 15.5319 17.2417 14.4067L17.0911 14.2561C17.1974 14.1675 17.2594 14.1143 17.2594 14.1143L15.3279 12.1828C15.3456 12.103 15.3899 12.0322 15.4431 11.979H22.6198V12.7498H16.9936L18.1366 13.8928C18.4378 14.1941 18.6859 14.3801 18.1986 14.7965C18.1809 14.8143 12.0408 20.0418 6.54744 21.5126C5.06779 21.9113 3.89825 21.8138 3.02995 21.4062C2.75528 21.2822 2.51605 21.1227 2.30341 20.9455C2.50719 21.2379 2.74641 21.5126 3.02108 21.7695C4.13746 22.8062 5.85635 23.5238 8.12456 23.6567C8.88653 23.701 9.6485 23.6479 10.4016 23.515C9.427 23.7276 8.43465 23.8339 7.45117 23.7719C4.20835 23.5858 2.01102 22.2302 1.02754 20.4405C0.744013 19.7405 0.619981 18.9963 0.664282 18.2343C0.690863 17.8444 0.75288 17.4457 0.868062 17.0559H0.859194Z"
        fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.1525 14.9735C1.62089 15.6646 1.31078 16.5595 1.27534 17.4367C1.24876 18.048 1.35509 18.6417 1.60318 19.1378C1.8424 19.6251 2.22338 20.0239 2.74614 20.2631C3.43723 20.582 4.403 20.6529 5.66114 20.3162C10.1887 19.1024 15.2036 15.2128 16.5946 14.0875L14.7871 12.28C14.6099 12.1028 14.6099 11.8282 14.7871 11.651C14.8757 11.5624 14.9909 11.5181 15.1061 11.5181H22.6195C22.8676 11.5181 23.0625 11.7219 23.0625 11.9611V18.9961C23.0625 19.2441 22.8587 19.4391 22.6195 19.4391C22.4955 19.4391 22.3803 19.3859 22.3006 19.3062L20.9893 17.9949C19.5362 19.315 13.3518 24.5425 7.4066 24.197C4.91689 24.0552 3.01195 23.2401 1.7538 22.0794C1.14245 21.5124 0.681709 20.8567 0.389323 20.1479C0.096937 19.4391 -0.0359593 18.6771 0.00834164 17.9063C0.0792231 16.719 0.557676 15.4963 1.47914 14.3799C1.5766 14.2559 1.68291 14.1318 1.78923 14.0255C1.95758 13.8394 2.24111 13.8306 2.41831 13.9989C2.59552 14.1673 2.60438 14.4508 2.44489 14.628C2.33857 14.7432 2.24111 14.8584 2.14365 14.9735H2.1525ZM2.37401 21.0782C2.09934 20.9542 1.86013 20.7947 1.64748 20.6175C1.85127 20.9099 2.09049 21.1845 2.36515 21.4415C3.48154 22.4781 5.20041 23.1958 7.46862 23.3287C13.6619 23.692 20.2539 17.5076 20.6791 17.0911L20.6969 17.0734C20.8741 16.8962 21.1576 16.8962 21.3259 17.0734L22.1854 17.9328V12.4218H16.1959L17.5869 13.8129C17.5869 13.8129 17.6047 13.8306 17.6135 13.8394C17.773 14.0255 17.7464 14.309 17.5604 14.4685C17.5426 14.4862 11.4025 19.7137 5.90923 21.1845C4.42958 21.5832 3.26003 21.4858 2.39173 21.0782H2.37401Z"
        fill="#014772" />
    </svg>
    <span class="font-weight-bold ml-1" style="color: #014772"
      >CR 2.0 NBU
    </span>
  </div>
</template>

<style scoped lang="scss"></style>

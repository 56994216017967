<template>
  <div>
    <div class="d-flex gap-3 mb-3">
      <a class="link" :href="nbuControlLink" target="_blank">
        Завантажити контроль НБУ
      </a>
      <a
        class="link"
        href="https://docs.google.com/document/d/1xwj62wLSZD5JdD661SbzixBI6DKp_IbHhzfLPz__GAg/edit?usp=sharing"
        target="_blank">
        Інструкція
      </a>
    </div>
    <v-data-table :headers="headers" :items="NBUErrors" items-value="id">
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { computed, PropType } from 'vue'
import { Dialog } from 'best-modules/plugins'
import { DataTableHeader } from 'best-modules/components'
import { CustomDate } from 'best-modules/utils'
import { NBUError } from '@/utils/types'
import { useUser } from '@/store/user'

export default {
  name: 'NBUErrors',
  props: {
    dialog: {
      type: Object as PropType<Dialog<'NBUErrors'>>,
    },
  },
  methods: { CustomDate },
  setup(props) {
    const { userData } = useUser()
    const nbuControlLink = computed<string>(() => {
      return userData.watch_company_user_role.company.typeId === 1
        ? 'https://bank.gov.ua/files/Taxonomy/Controls_FC.xlsx'
        : 'https://bank.gov.ua/files/Taxonomy/Controls_CU.xlsx'
    })

    const NBUErrors: NBUError[] =
      props.dialog.dialogData.NBUErrors.map(e => {
        return {
          ...e,
          created_at: new CustomDate(e.created_at.substring(0, 19)).toString({
            time: true,
          }),
        }
      }) || []
    const headers: DataTableHeader[] = [
      {
        title: 'Поле',
        key: 'name',
      },
      {
        title: 'Номер контроля НБУ',
        key: 'NBUControlId',
      },
      {
        title: 'Ідентифікатор поля НБУ',
        key: 'NBURequisiteId',
      },
      {
        title: 'Дата',
        key: 'created_at',
      },
    ]

    return { headers, NBUErrors, nbuControlLink }
  },
}
</script>

<style scoped lang="scss"></style>

import { Query, sendRequest } from 'best-modules/utils'
import { PaginationList, User, UserCredentials } from '@/utils/types'

const getUserData = () => {
  return sendRequest({
    name: 'getUserData',
    url: '/admin/user/me',
  })
}

const getUserList = (
  query: Query<'page' | 'search'>
): Promise<PaginationList<User>> => {
  return sendRequest({
    name: 'userList',
    url: {
      url: '/admin/user/getAll',
      query,
    },
  })
}

const getUserSingle = (userId: string): Promise<User> => {
  return sendRequest({
    name: 'userSingle',
    url: `/admin/user/single/${userId}`,
  })
}

const createUser = (userData: UserCredentials): Promise<User> => {
  return sendRequest({
    name: 'userCreate',
    url: '/admin/user/create',
    method: 'post',
    payload: userData,
  })
}

const updateUser = (userData: User): Promise<User> => {
  return sendRequest({
    name: 'userUpdate',
    url: `/admin/user/update/${userData.id}`,
    method: 'put',
    payload: userData,
  })
}

const deactivateUser = (userId: string): Promise<void> => {
  return sendRequest({
    name: 'userDeactivate',
    url: `/admin/user/deactivate/${userId}`,
  })
}

const activateUser = (userId: string): Promise<void> => {
  return sendRequest({
    name: 'userActivate',
    url: `/admin/user/activate/${userId}`,
  })
}

const changeUser = (userId: string): Promise<User> => {
  return sendRequest({
    name: 'userChange',
    url: `/admin/user/changeUser/${userId}`,
    method: 'get',
  })
}

const changeUserPassword = (password, userId) => {
  return sendRequest({
    name: 'userChangePassword',
    url: `/admin/user/changePassword/${userId}`,
    method: 'post',
    payload: { password: password },
  })
}

const createUserCompany = (userId, userCompanyData) => {
  return sendRequest({
    name: 'createUserCompany',
    url: '/admin/companyUserRole/create',
    method: 'post',
    payload: {
      userId: userId,
      companyId: userCompanyData.companyId,
      role: userCompanyData.role,
    },
  })
}

export {
  getUserSingle,
  getUserList,
  getUserData,
  createUser,
  updateUser,
  activateUser,
  deactivateUser,
  changeUser,
  changeUserPassword,
  createUserCompany,
}

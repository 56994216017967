<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <b-autocomplete
          v-model="userCompanyData.companyId"
          label="Компанія"
          :items="$directory.get('companyList')"
          item-value="id"
          item-title="shortName"
          placeholder="оберіть компанію"
          :loading="$loading.isLoading('companyList')"
          :error-messages="getErrorMessages(v$.companyId)"
          @blur="v$.companyId.$touch()"
          @focus="$directory.fill('companyList')"></b-autocomplete>
      </v-col>
      <v-col cols="12" md="12" sm="12">
        <div class="d-flex align-center gap-10 mb-5">
          <div
            class="access"
            :style="{
              border: `${userCompanyData.role === 'adminCompany' ? '1px solid blue' : '1px solid #e7e7e7'}`,
            }">
            <div class="access_wrapper">
              <div class="d-flex align-center mb-3">
                <v-radio-group v-model="userCompanyData.role" hide-details>
                  <v-radio color="primary" value="adminCompany">
                    <template #label>
                      <h4 class="text-black">Перегляд і керування</h4>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
              <div class="access_wrapper_info">
                <div class="d-flex align-center">
                  <v-icon class="mr-1" color="green">mdi-check</v-icon>
                  <span>Переглядати всю інформацію по пакетам</span>
                </div>
                <div>
                  <div class="d-flex mt-2 align-center">
                    <v-icon class="mr-1" color="green">mdi-check</v-icon>
                    <span>Керувати пакетами</span>
                  </div>
                  <div class="d-flex flex-column" style="padding-left: 28px">
                    <span>- Створювати пакет</span>
                    <span>- Підписувати та відправляти дані до НБУ</span>
                    <span>- Перевіряти статус</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="access"
            :style="{
              border: `${userCompanyData.role === 'userCompany' ? '1px solid blue' : '1px solid #e7e7e7'}`,
            }">
            <div class="access_wrapper">
              <div class="d-flex align-center mb-3">
                <v-radio-group v-model="userCompanyData.role" hide-details>
                  <v-radio color="primary" value="userCompany">
                    <template #label>
                      <h4 class="text-black">Тільки перегляд</h4>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
              <div class="access_wrapper_info">
                <div class="d-flex align-center">
                  <v-icon class="mr-1" color="green">mdi-check</v-icon>
                  <span>Переглядати всю інформацію по пакетам</span>
                </div>
                <div>
                  <div class="d-flex mt-2 align-center">
                    <v-icon class="mr-1" color="red">mdi-close</v-icon>
                    <span>Керувати пакетами</span>
                  </div>
                  <div class="d-flex flex-column" style="padding-left: 28px">
                    <span>- Створювати пакет</span>
                    <span>- Підписувати та відправляти дані до НБУ</span>
                    <span>- Перевіряти статус</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { ref } from 'vue'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { getErrorMessages } from 'best-modules/utils'
import { createUserCompany } from '@/request/user'

export default {
  props: {
    dialog: { type: Object },
  },
  methods: { getErrorMessages },
  setup(props) {
    const userCompanyData = ref({})

    const rules = {
      companyId: { required },
      role: { required },
    }

    const v$ = useVuelidate(rules, userCompanyData)

    const submit = () => {
      return createUserCompany(
        props.dialog.dialogData.userId,
        userCompanyData.value
      )
    }

    return {
      v$,
      userCompanyData,
      submit,
    }
  },
}
</script>

<style lang="scss" scoped>
.access {
  cursor: default;
  width: 50%;
  border-radius: 4px;

  &_wrapper {
    padding: 15px;
    &_info {
      padding-left: 20px;
      span {
        font-size: 13px;
      }
    }
  }
}
</style>

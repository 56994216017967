<template>
  <div>
    <div class="mb-5" :class="mobile ? 'w-100' : 'w-50'">
      <h2 class="mb-2">Як налаштувати інтеграцію</h2>
      <span>
        Інтеграція з вашою ERP суттєво прискорить створення та надсилання
        звітності до Регулятора та дозволить працювати у звичному для вас
        програмному середовищі.
      </span>
    </div>
    <div>
      <Item :content="content1" image="oneC" />
      <Item :content="content2" image="api" class="mt-10 mb-10" />
      <Item :content="content3" image="best" class="mt-10" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Item from '@/pages/integration/components/Item.vue'
import { useDisplay } from 'vuetify'

export default defineComponent({
  components: {
    Item,
  },
  setup() {
    const content1 = [
      {
        type: 'title',
        data: 'Інтеграція з 1С',
      },
      {
        type: 'paragraph',
        data: 'Якщо всі ваші дані зберігаються та формуються в 1С, ви можете отримати обробку для 1С. Це дозволяє:',
      },
      {
        type: 'list',
        data: [
          'Обирати і формувати необхідні дані для направлення їх у сервіс CR2.0' +
            '(формат XML/JSON)',
          'Імпортувати звіти у сервіс «CR2.0» з заповненими даними по договору',
          'Оновлювати статуси пакетів, що надіслані в НБУ',
          'Вивантажувати список не провалідованих пакетів з сервісу «CR2.0» ',
        ],
      },
      {
        type: 'paragraph',
        data: 'Налаштувавши інтеграцію з CR2.0, ви будете мати актуальний статус даних у вашій 1С. Процесс підписання відбуватиметься через WEB інтерфейс «CR2.0».',
      },
      {
        type: 'tip',
        data: [
          {
            type: 'text',
            data: 'Для отримання обробки для 1С, оберіть версію 1С, у котрій ви працюєте. Там ви знайдете обробку для 1С та інструкцію з її встановлення.',
          },
        ],
      },
    ]
    const content2 = [
      {
        type: 'title',
        data: 'Інтеграція з іншими ERP',
      },
      {
        type: 'paragraph',
        data: 'Якщо для формування звітності ви використовуєте будь-яку CRM/ERP систему, можете налаштувати інтеграції за допомогою API. Це дозволяє:',
      },
      {
        type: 'list',
        data: [
          'Імпортувати дані, що сформовані у вашій CRM/ERP системі у сервіс «CR2.0» з заповненими даними по контрагенту та договору',
          'Отримувати статуси по звітам, що надіслані в НБУ',
          'Видаляти пакети звітів з сервісу «CR2.0»',
        ],
      },
      {
        type: 'paragraph',
        data: 'Налаштувавши інтеграцію з вашою ERP системою, ви можете весь процес роботи з відправкою та підписанням звітності реалізувати у своїй ERP системі або перенести дані у WEB інтерфейс системи.',
      },
      {
        type: 'tip',
        data: [
          {
            type: 'text',
            data: 'Для самостійного налаштування інтеграції використовуйте інструкцію до API за',
          },
          {
            type: 'link',
            data: 'посиланням',
            href: 'https://docs.google.com/document/d/1-R4xJ4WulOqGqC3ODQ3MPV9XrkITKUr0/edit?usp=sharing&ouid=113503973655249618045&rtpof=true&sd=true',
          },
        ],
      },
    ]
    const content3 = [
      {
        type: 'title',
        data: 'Інтеграція з підписанням прямо в CRM/ERP',
      },
      {
        type: 'list',
        data: [
          'Ця можливість актуальна для компаній з великим обсягом вихідних документів і дозволяє підписувати більше ніж 10 000 документів за 30 хвилин',
          'Вона дозволяє зручно підписувати документи без зайвих зусиль',
        ],
      },
      {
        type: 'btn',
        data: 'Дізнатися більше',
      },
    ]

    const { mobile } = useDisplay()

    return {
      content1,
      content2,
      content3,
      mobile,
    }
  },
})
</script>

<style lang="scss"></style>

<template>
  <b-select
    style="border-color: green"
    bg-color="grey-lighten-4"
    placeholder=" "
    :menu-icon="icon"
    v-bind="{ ...$attrs, ...selectProps }">
    <template
      v-for="slotName in Object.keys($slots).filter(
        slot => slot !== 'prepend-inner'
      )"
      :key="slotName"
      #[slotName]="slotProps">
      <slot :name="slotName" v-bind="slotProps || {}"></slot>
    </template>
    <template #prepend-inner>
      <span style="font-size: 14px" class="text-blue-grey-lighten-1">
        {{ label }}:
      </span>
    </template>
  </b-select>
</template>

<script lang="ts">
export default {
  name: 'FilterSelect',
  props: {
    label: {
      type: String,
    },
    icon: {
      type: String,
      default: 'mdi-chevron-down',
    },
    selectProps: {
      type: Object,
      default: Object,
    },
  },
}
</script>

<style scoped></style>

<template>
  <b-input-number
    v-model="value"
    :max-value="maxValue"
    :decimal="decimal"
    :readonly="readonly"
    placeholder="Не вказано"
    :label="label"
    hide-details
    rank-spacing>
    <template v-if="!readonly" #append-inner>
      <slot name="append-inner"></slot>
    </template>
  </b-input-number>
</template>

<script lang="ts">
import { BInputNumber } from 'best-modules/components/index'
import { computed } from 'vue'

export default {
  name: 'PackageTableInputNumber',
  components: { BInputNumber },
  emits: ['update:model-value'],
  props: {
    modelValue: {
      type: Number,
    },
    label: {
      type: String,
    },
    maxValue: {
      type: Number,
    },
    coefficient: {
      type: Number,
    },
    readonly: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const decimal = props.coefficient
      ? String(props.coefficient / 10).length
      : 0
    const value = computed<number>({
      get() {
        const v = props.modelValue
        if (typeof v === 'number') {
          return v / (props.coefficient || 1)
        } else {
          return null
        }
      },
      set(v) {
        if (typeof v === 'number') {
          const multipliedValue = v * (props.coefficient || 1)
          const roundedValue = Number(multipliedValue.toFixed(0))
          emit('update:model-value', roundedValue)
        } else {
          emit('update:model-value', null)
        }
      },
    })

    return { value, decimal }
  },
}
</script>

<style scoped lang="scss"></style>

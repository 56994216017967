<template>
  <div
    v-if="typeof isReaded === 'boolean'"
    class="mt-6"
    style="color: rgb(var(--v-theme-success))">
    <span v-if="isReaded"> Підпис успішно зчитано </span>
    <span v-else style="color: rgb(var(--v-theme-error))">
      Помилка зчитування підпису
    </span>
  </div>
</template>

<script lang="ts">
export default {
  name: 'ReadKeyStatus',
  props: {
    isReaded: {
      type: Boolean,
    },
  },
}
</script>

<style scoped lang="scss"></style>

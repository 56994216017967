<template>
  <div class="mb-3">
    <b-input
      v-model="searchStr"
      class="mb-3"
      hide-details
      type="text"
      placeholder="Пошук">
    </b-input>

    <!-- 400px (old 305px) - height of all screen elements expect table -->
    <v-data-table-virtual
      v-model="value"
      style="height: calc(100vh - 400px)"
      :items="[...(directory || [])]"
      :search="searchStr"
      :headers="packageDirectoryHeaders"
      hover
      show-select
      item-value="code"
      :item-selectable="item => !item.closeDate"
      :select-strategy="multiple ? 'all' : 'single'"
      :items-per-page="-1">
      <template #bottom> </template>
      <template #[`item.openDate`]="{ item }">
        <span>{{
          item.openDate ? new CustomDate(item.openDate).toString() : '---'
        }}</span>
      </template>
      <template #[`item.closeDate`]="{ item }">
        <span>{{
          item.closeDate ? new CustomDate(item.closeDate).toString() : '---'
        }}</span>
      </template>
    </v-data-table-virtual>
  </div>
</template>

<script lang="ts">
import { computed, PropType, ref } from 'vue'
import { Collection } from 'best-modules/utils'
import {
  PackageTableValue,
  PackageTableValueF170,
} from '@/pages/report/package/package-table/types'
import { packageDirectoryHeaders } from '@/utils/tableHeaders'
import { keepArray, CustomDate } from 'best-modules/utils'

export default {
  name: 'PackageDirectorySelect',
  props: {
    modelValue: {
      type: [String || Array] as PropType<
        Exclude<PackageTableValue, PackageTableValueF170 | null>
      >,
    },
    directory: {
      type: Object as PropType<Collection<'code'>>,
    },
    multiple: {
      type: Boolean as PropType<boolean>,
    },
  },
  data() {
    return {
      CustomDate,
      packageDirectoryHeaders,
    }
  },
  emits: ['update:model-value'],
  setup(props, { emit }) {
    const value = computed({
      get() {
        return keepArray(props.modelValue)
      },
      set(val: string[]) {
        const filteredArray = val.filter(v => {
          return v && props.directory.getItem(v)
        })
        if (!props.multiple) {
          return emit('update:model-value', filteredArray[0])
        } else {
          return emit('update:model-value', filteredArray)
        }
      },
    })
    const searchStr = ref(null)

    return { value, searchStr }
  },
}
</script>

<style scoped lang="scss"></style>

import moment from 'moment'
const setQrData = data => {
  localStorage.setItem('qrCode', data.url)
  localStorage.setItem('userId', data?.user?.id)
  localStorage.setItem('userName', data?.user?.name)
  localStorage.setItem('date', moment().format('YYYY-MM-DD'))
}
const removeQrData = () => {
  localStorage.removeItem('qrCode')
  localStorage.removeItem('userId')
  localStorage.removeItem('userName')
  localStorage.removeItem('date')
}

const getQrData = () => {
  if (localStorage.getItem('qrCode')) {
    return {
      url: localStorage.getItem('qrCode'),
      user: {
        id: localStorage.getItem('userId'),
        name: localStorage.getItem('userName'),
      },
    }
  } else {
    return null
  }
}

const setToken = token => {
  localStorage.setItem('token', token)
}
const removeToken = () => {
  localStorage.removeItem('token')
}

const getToken = () => {
  return localStorage.getItem('token') || null
}

export { setQrData, removeQrData, getQrData, setToken, removeToken, getToken }

import { sendRequest, Query } from 'best-modules/utils'

import type {
  PaginationList,
  Report,
  Package,
  PackageData,
  PackageCredentials,
  PersonFullSchemaCredentials,
  PersonShortSchemaCredentials,
  PersonFull,
  PersonShort,
  RelatedPerson,
  PackageParseType,
} from '@/utils/types'
import {
  PackageDirectoryItem,
  PackageDirectoryName,
} from '@/store/package-directory/types'
import { download, getFile } from 'best-modules/utils/fileHandlers'
import { Url } from 'best-modules/utils/request/url'

const getReportList = (
  query: Query<'page' | 'month' | 'year'>
): Promise<PaginationList<Report>> => {
  return sendRequest({
    name: 'reportList',
    url: { url: '/admin/report/getAll', query },
  })
}

const getReportSingle = (reportId: string): Promise<Report> => {
  return sendRequest({
    name: 'reportSingle',
    url: `/admin/report/single/${reportId}`,
  })
}

const createReport = (report: {
  year: number
  month: number
}): Promise<Report> => {
  const request = {
    period: `${report.year}-${String(report.month).padStart(2, '0')}-01`,
  }
  return sendRequest({
    name: 'reportCreate',
    url: '/admin/report/create',
    method: 'post',
    payload: request,
  })
}
const updateReport = (report: Report): Promise<Report> => {
  return sendRequest({
    name: 'reportUpdate',
    url: `/admin/report/update/${report.id}`,
    method: 'put',
    payload: report,
  })
}

const getReportPackageList = (
  reportId: string,
  query: Query<'page' | 'statusId' | 'authorId'>
): Promise<PaginationList<Report>> => {
  return sendRequest({
    name: 'packageList',
    url: { url: `/admin/report/${reportId}/package/getAll`, query },
  })
}

const getPackageSingle = (packageId: string): Promise<Package> => {
  return sendRequest({
    name: 'packageSingle',
    url: `/admin/report/package/single/${packageId}`,
  })
}

const getPackageSchemaBody = (): Promise<
  Omit<PackageData, 'person_short' | 'person_full'>
> => {
  return sendRequest({
    name: 'packageSchemaBody',
    url: '/admin/template/schema/body',
    method: 'get',
  })
}
const updateValidationsSchemas = () => {
  return sendRequest({
    name: 'updateValidationsSchemas',
    url: '/admin/template/validationsSchemas/update',
    method: 'get',
  })
}
const getPackageSchemaPersonFull = (
  credentials: PersonFullSchemaCredentials
): Promise<{ person_full: [PersonFull] }> => {
  return sendRequest({
    name: 'packageSchemaPersonFull',
    method: 'post',
    url: '/admin/template/schema/personFull',
    payload: credentials,
  })
}
const getPackageSchemaPersonShort = (
  credentials: PersonShortSchemaCredentials
): Promise<{
  person_short: [PersonShort]
  related_person: [RelatedPerson]
}> => {
  return sendRequest({
    name: 'packageSchemaPersonShort',
    method: 'post',
    url: '/admin/template/schema/personShort',
    payload: credentials,
  })
}

const getUserCompanyPersonShort = (): Promise<PersonShort> => {
  return sendRequest({
    name: 'userCompanyPersonShort',
    method: 'get',
    url: '/admin/company/json',
  })
}

const createPackage = (
  packageData: PackageCredentials & { createType: 'W' | 'E' | 'J' }
): Promise<Package> => {
  console.log(packageData)
  return sendRequest({
    name: 'packageCreate',
    url: '/admin/report/package/create',
    method: 'post',
    payload: {
      reportId: packageData.reportId,
      data: packageData.data,
      createType: packageData.createType,
    },
  })
}

const updatePackage = (
  packageData: PackageData,
  packageId: string
): Promise<Package> => {
  console.log('updatePackageRequest', packageData)
  return sendRequest({
    name: 'packageUpdate',
    url: `/admin/report/package/update/${packageId}`,
    method: 'put',
    payload: {
      data: packageData,
    },
  })
}

const sendPackage = packages => {
  return sendRequest({
    name: 'sendPackage',
    url: '/admin/nbu/send',
    method: 'post',
    payload: {
      packages,
    },
  })
}

const deletePackage = (arr: any[]) => {
  if (arr.length) {
    return sendRequest({
      name: 'packageDelete',
      url: '/admin/report/package/delete',
      method: 'delete',
      payload: { reportPackagesId: arr },
    })
  } else {
    return Promise.reject(new Error('Array is empty'))
  }
}

const getPackageTemplate = () => {
  return sendRequest({
    name: 'getPackageTemplate',
    url: '/admin/template/excel',
    method: 'get',
  })
}

const getPackageDomains = () => {
  return sendRequest({
    name: 'getPackageDomains',
    url: '/admin/template/domains',
    method: 'get',
  })
}

const copyPackage = (reportId, packageId) => {
  const req = {
    reportId: reportId,
    reportPackageId: packageId,
  }
  return sendRequest({
    name: 'packageCopy',
    url: '/admin/report/package/copy',
    method: 'post',
    payload: req,
  })
}

const parsePackage = (
  parseData: {
    file: string
  },
  parseFrom: PackageParseType
): Promise<{ file: string; parsedData: Array<{ data: PackageData }> }> => {
  const parseTypes: Record<PackageParseType, Url> = {
    excel: '/admin/report/package/parse',
    json: '/admin/report/package/json/parse',
  }
  const url = parseTypes[parseFrom]
  return sendRequest({
    name: 'packageParse',
    url,
    method: 'post',
    payload: parseData,
  })
}

const packageValidation = (packageId: string) => {
  return sendRequest({
    name: 'packageParseValidation',
    url: `/admin/report/package/validation/${packageId}`,
    method: 'get',
  })
}

const getPackageDirectory = (): Promise<
  Record<PackageDirectoryName, PackageDirectoryItem>
> => {
  return sendRequest({
    name: 'getPackageDirectory',
    url: '/admin/list/catalogs ',
  }).then(
    (res: { data: Record<PackageDirectoryName, PackageDirectoryItem> }) => {
      return res.data
    }
  )
}

const sendPackageToRevision = (packageId: string): Promise<Package> => {
  return sendRequest({
    name: 'sendPackageToRevision',
    url: '/admin/report/package/revision',
    method: 'post',
    payload: {
      reportPackageId: packageId,
    },
  })
}

const exportValidation = (reportPackageId: string): Promise<void> => {
  return sendRequest({
    name: 'exportValidation',
    method: 'post',
    url: '/admin/report/package/exportValidation',
    payload: { reportPackageId },
  }).then(async (res: { file: string }) => {
    const file = (await getFile(res.file)) as string
    await download(file, res.file.split('/').at(-1))
  })
}

const exportPackage = (reportPackageId: string): Promise<void> => {
  return sendRequest({
    name: 'exportPackage',
    method: 'post',
    url: '/admin/report/package/json/export',
    payload: { reportPackageId },
  }).then(async (res: { file: string }) => {
    const file = (await getFile(res.file)) as string
    await download(file, res.file.split('/').at(-1))
  })
}

export {
  getReportList,
  getReportSingle,
  getReportPackageList,
  createReport,
  updateReport,
  getPackageSingle,
  getPackageSchemaBody,
  getPackageSchemaPersonFull,
  getPackageSchemaPersonShort,
  getUserCompanyPersonShort,
  createPackage,
  updatePackage,
  deletePackage,
  getPackageTemplate,
  getPackageDomains,
  sendPackage,
  copyPackage,
  parsePackage,
  packageValidation,
  getPackageDirectory,
  updateValidationsSchemas,
  sendPackageToRevision,
  exportValidation,
  exportPackage,
}

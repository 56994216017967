<template>
  <div>
    <BSectionLoader :loading="$loading.isLoading('packageSchemaPersonShort')">
      <div class="mb-3">
        <span v-if="dialog.dialogData.subtitle">{{
          dialog.dialogData.subtitle
        }}</span>
        <span>Оберіть тип особи, щоб заповнити дані:</span>
      </div>
      <package-directory-select
        v-model="personSchemaCredentials.personType"
        :directory="directory.F082"></package-directory-select>
    </BSectionLoader>
  </div>
</template>

<script lang="ts">
import { PropType, reactive } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { getErrorMessages } from 'best-modules/utils'
import PackageDirectorySelect from './PackageDirectorySelect.vue'
import { PersonShortSchemaCredentials } from '@/utils/types'
import { usePackageDirectory } from '@/store/package-directory'
import { getPackageSchemaPersonShort } from '@/request/report'
import { v4 as uuid } from 'uuid'
import { Dialog } from 'best-modules/plugins'

export default {
  name: 'PackagePersonShort',
  components: { PackageDirectorySelect },
  props: {
    dialog: {
      type: Object as PropType<Dialog<'PackagePersonShort'>>,
    },
  },
  methods: { getErrorMessages },
  setup() {
    const { directory, fillDirectory } = usePackageDirectory()
    fillDirectory()
    const personSchemaCredentials = reactive<PersonShortSchemaCredentials>({
      personType: '1',
    })

    const submit = () => {
      return getPackageSchemaPersonShort(personSchemaCredentials).then(res => {
        const person_id_short = uuid()
        res.person_short[0].person_id_short = person_id_short
        res.person_short[0].f082_person_type =
          personSchemaCredentials.personType
        res.related_person[0].person_info.person_id_short = person_id_short

        return res
      })
    }

    const rules = {
      personType: { required },
    }

    const v$ = useVuelidate(rules, personSchemaCredentials)

    return { personSchemaCredentials, v$, submit, directory }
  },
}
</script>

<style scoped lang="scss"></style>

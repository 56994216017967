<template>
  <div style="margin-top: -20px">
    <v-tabs v-model="selectedDirectory" class="mb-3">
      <v-tab v-if="col.directory" :value="col.directory">
        {{ col.directory }}
      </v-tab>
      <v-tab v-if="hasF170" value="F170">F170</v-tab>
    </v-tabs>
    <v-window v-model="selectedDirectory">
      <v-window-item :value="col.directory">
        <package-directory-select
          v-model="localValue"
          :multiple="col.multiple"
          :directory="directory[col.directory]"
          @update:model-value="
            localF170Value = null
          "></package-directory-select>
      </v-window-item>
      <v-window-item value="F170">
        <package-directory-select
          v-model="localF170Value"
          :multiple="col.multiple"
          :directory="directory.F170"
          @update:model-value="localValue = null"></package-directory-select>
      </v-window-item>
    </v-window>
  </div>
</template>

<script lang="ts">
import { PropType, ref } from 'vue'
import {
  PackageTableCol,
  PackageTableValue,
} from '@/pages/report/package/package-table/types'
import { PackageDirectoryName } from '@/store/package-directory/types'
import PackageDirectorySelect from './PackageDirectorySelect.vue'
import { usePackageDirectory } from '@/store/package-directory'
import { Dialog } from 'best-modules/plugins/dialog/types'

export default {
  name: 'PackageDirectoryIndex',
  components: { PackageDirectorySelect },
  props: {
    dialog: {
      type: Object as PropType<Dialog<'PackageDirectory'>>,
      required: true,
    },
  },
  setup(props) {
    const hasF170: boolean = props.dialog.dialogData.hasF170
    const value: PackageTableValue = props.dialog.dialogData.value
    const col: PackageTableCol = props.dialog.dialogData.col
    const { directory } = usePackageDirectory()
    const localF170Value = ref(
      // @ts-ignore
      hasF170 && 'f170' in value ? value.f170 : null
    )
    const localValue = ref(
      // @ts-ignore
      hasF170 && 'value' in value ? value.value : value
    )
    const selectedDirectory = ref<PackageDirectoryName>(col.directory || 'F170')

    const submit = () => {
      if (hasF170) {
        if (localF170Value.value) {
          return Promise.resolve({ f170: localF170Value.value })
        } else {
          return Promise.resolve({ value: localValue.value || null })
        }
      } else {
        return Promise.resolve(localValue.value)
      }
    }

    return {
      localValue,
      localF170Value,
      selectedDirectory,
      hasF170,
      directory,
      submit,
      col,
    }
  },
}
</script>

<style scoped lang="scss"></style>

<template>
  <div>
    <v-alert
      class="mb-5"
      title="Увага"
      border-color="warning"
      border
      variant="flat"
      text="Скопіюйте та збережіть токен інтеграції, після закриття модального вікна переглянути токен ще раз буде не можливо">
    </v-alert>
    <b-input
      :model-value="dialog.dialogData.plainTextToken"
      :readonly="true"
      label="Токен інтеграції">
      <template #append>
        <v-btn
          icon="mdi-content-copy"
          variant="plain"
          density="comfortable"
          @click="copyText(dialog.dialogData.plainTextToken)"></v-btn>
      </template>
    </b-input>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue'
import { Dialog } from 'best-modules/plugins'
import { copyText } from 'best-modules/utils'

export default {
  name: 'CompanyTokenPlainText',
  props: {
    dialog: {
      type: Object as PropType<Dialog<'CompanyTokenPlainText'>>,
    },
  },
  methods: { copyText },
}
</script>

<style scoped lang="scss"></style>

import type { Report } from '@/utils/types'
import { CustomDate, ISODate } from 'best-modules/utils'

const formatPeriod = (period: Report['period']): string => {
  const date: ISODate = `${period}-01`
  const formatedDate = new CustomDate(date).toString()

  return formatedDate.slice(3)
}

export { formatPeriod }

import axios from 'axios'
import { getToken } from '@/store/auth/helpers.ts'
import router from '@/router/index.js'

function setInterceptor(/* crmRoute */) {
  axios.interceptors.request.use(
    // before request is sent
    req => {
      const token = getToken()

      if (token && !req.headers.Authorization) {
        req.headers['Authorization'] = `Bearer ${token}`
      }

      return req
    },
    // request err
    err => {
      return Promise.reject(err)
    }
  )

  axios.interceptors.response.use(
    // Любой статус в диапазоне 200 считается успешным ответом
    res => {
      return Promise.resolve(res)
    },
    err => {
      if (err?.response?.status === 404) {
        return router.push({ name: 'notFound' })
      }
      return Promise.reject({ ...err, isAxios: true })
    }
  )

  return axios
}

export default setInterceptor()

const keyMediaTypes: string[] = [
  'гнучкий диск',
  "з'ємний диск",
  'оптичний диск',
  'е.ключ Aladdin eToken R2',
  'е.ключ Aladdin eToken PRO',
  'е.ключ Aladdin eToken PRO (PKCS#11)',
  'смарт-карта BIFIT Integra 1.0',
  'е.ключ BIFIT iToken',
  'е.ключ ІІТ Алмаз-1К',
  'е.ключ ІІТ Алмаз-1К (носій)',
  'е.ключ ІІТ Алмаз-1К (Bluetooth)',
  'закордонний біометричний паспорт (ел. паспорт)',
  'файлова система (каталоги системи)',
  'файлова система (каталоги користувача)',
  'ID-карта громадянина (БЕН)',
  'криптомод. ІІТ Гряда-301',
  'криптомод. ІІТ Гряда-61 (PKCS#11)',
  'е.ключ ІІТ Алмаз-1К (PKCS#11)',
  'е.ключ ІІТ Кристал-1 (PKCS#11)',
  'криптомодуль ІІТ Гряда-301 (PKCS#11)',
  'е.ключ ІІТ Алмаз-1К (PKCS#11, віртуальний)',
  'е.ключ ІІТ Кристал-1 (PKCS#11, віртуальний)',
  'е.ключ SafeNet iKey (PKCS#11, RSA)',
  'е.ключ чи смарт-карта Avest (PKCS#11)',
  'е.ключ Ефіт Key (PKCS#11)',
  'е.ключ чи смарт-карта Автор (PKCS#11)',
  'е.ключ чи смарт-карта Автор 338 (PKCS#11)',
  'смарт-карта Техноконс. TEllipse3 (PKCS#11)',
  'е.ключ DBO Soft iToken (PKCS#11)',
  'криптомодуль Сайфер Шифр-HSM (PKCS#11)',
  'криптомод. ІІТ Гряда-61 (PKCS#11, носій)',
  'е.ключ ІІТ Алмаз-1К (PKCS#11, носій)',
  'е.ключ ІІТ Кристал-1 (PKCS#11, носій)',
  'е.ключ Aladdin eToken (PKCS#11, носій)',
  'е.ключ Aladdin JaCarta ASE (PKCS#11, носій)',
  'е.ключ SafeNet iKey (PKCS#11, носій)',
  'е.ключ Ефіт Key (PKCS#11, носій)',
  'е.ключ чи с.-карта Aladdin JaCarta (PKCS#11, носій)',
  'е.ключ чи с.-карта G&D SafeSign (PKCS#11, носій)',
  'е.ключ чи смарт-карта Avest (PKCS#11, носій)',
  'е.ключ чи смарт-карта Автор (PKCS#11, носій)',
  'е.ключ чи смарт-карта Автор 338 (PKCS#11, носій)',
  'смарт-карта Gemalto IDPrime (PKCS#11, носій)',
  'смарт-карта Техноконс. TEllipse',
  'см.-карта чи е.ключ Техноконс',
]

export { keyMediaTypes }

<template>
  <div>
    <div class="d-flex align-center justify-space-between mb-10">
      <h2>Зміна пароля</h2>
      <span class="link">
        <router-link :to="{ name: 'login' }"> Авторизуватись </router-link>
      </span>
    </div>
    <b-form
      v-if="!resetPasswordData.userId"
      :submit="sendCode"
      hide-action-buttons>
      <b-input-phone
        v-model="sendCodeData.phone"
        label="Телефон"
        :error-messages="getErrorMessages(v$SendCode.phone)"
        @blur="v$SendCode.phone.$touch()"></b-input-phone>
      <v-btn
        :loading="$loading.isLoading('resetPasswordSendCode')"
        type="submit"
        size="large"
        class="mt-3 w-100">
        Надіслати код
      </v-btn>
    </b-form>

    <b-form v-else :submit="resetPassword" hide-action-buttons>
      <div>
        Введіть код із СМС, відпревлений на номер
        <b>{{ sendCodeData.phone }}</b>
      </div>
      <v-otp-input
        v-model="resetPasswordData.verificationCode"
        length="5"
        type="number"
        :error="v$ResetPassword.verificationCode.$error"></v-otp-input>
      <b-input-password
        v-model="resetPasswordData.password"
        label="Новий пароль"
        :error-messages="getErrorMessages(v$ResetPassword.password)"
        @blur="v$ResetPassword.password.$touch()"></b-input-password>

      <v-btn
        :loading="$loading.isLoading('resetPassword')"
        type="submit"
        size="large"
        class="mt-3 w-100">
        Змінити пароль
      </v-btn>
    </b-form>
  </div>
</template>

<script lang="ts">
import { BForm, BInputPhone, BInputPassword } from 'best-modules/components'
import { getErrorMessages } from 'best-modules/utils'
import { reactive } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { minLength, required } from '@vuelidate/validators'
import {
  resetPasswordSendCode as sendCodeRequest,
  resetPassword as resetPasswordRequest,
} from '@/request/auth'
import { useRouter } from 'vue-router'

export default {
  name: 'ResetPassword',
  components: { BForm, BInputPhone, BInputPassword },
  methods: { getErrorMessages },
  setup() {
    const router = useRouter()
    const sendCodeData = reactive({
      phone: null,
    })
    const rulesSendCode = {
      phone: {
        required,
        minLength: minLength(23),
      },
    }
    const v$SendCode = useVuelidate(rulesSendCode, sendCodeData)

    const resetPasswordData = reactive({
      userId: null,
      password: null,
      verificationCode: null,
    })
    const rulesResetPassword = {
      password: { required },
      verificationCode: { required, minLength: minLength(5) },
    }
    const v$ResetPassword = useVuelidate(rulesResetPassword, resetPasswordData)

    const sendCode = async () => {
      await v$SendCode.value.$validate()
      if (!v$SendCode.value.$invalid) {
        const { userId } = await sendCodeRequest(sendCodeData.phone)
        resetPasswordData.userId = userId
      }
    }

    const resetPassword = async () => {
      await v$ResetPassword.value.$validate()

      if (!v$ResetPassword.value.$invalid) {
        return resetPasswordRequest(resetPasswordData).then(() => {
          router.push({ name: 'login' })
        })
      }
    }

    return {
      sendCodeData,
      resetPasswordData,
      v$ResetPassword,
      v$SendCode,
      resetPassword,
      sendCode,
    }
  },
}
</script>

<style scoped lang="scss"></style>

import { sendRequest } from 'best-modules/utils'

const getPublicKey = () => {
  return sendRequest({
    name: 'getPublicKey',
    url: '/api/publicKey',
    method: 'get',
    headers: {
      // @ts-ignore
      API_KEY: import.meta.env.VITE_SIGN_API_KEY,
    },
  }).then(res => {
    return res.publicKey
  })
}

export { getPublicKey }

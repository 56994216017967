<template>
  <div class="mb-4">
    <b-autocomplete
      v-model="copyData.reportId"
      :items="$directory.get('reportList')"
      placeholder="Оберіть зі списку"
      item-value="id"
      item-title="period"
      :loading="$loading.isLoading('reportList')"
      :error-messages="getErrorMessages(v$.reportId)"
      hide-details
      @blur="v$.reportId.$touch()">
    </b-autocomplete>
  </div>
</template>

<script lang="ts">
import { reactive } from 'vue'
import { copyPackage } from '@/request/report'
import router from '@/router'
import { fillDirectory } from '@/directory'
import { getErrorMessages } from 'best-modules/utils'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { setSnackbar } from 'best-modules/plugins'

export default {
  name: 'PackageCopy',
  props: {
    dialog: {
      type: Object,
    },
  },
  methods: { getErrorMessages },
  setup(props) {
    fillDirectory({
      name: 'reportList',
      force: true,
    })
    const copyData = reactive({
      reportId: null,
    })
    const rules = {
      reportId: { required },
    }
    const v$ = useVuelidate(rules, copyData)

    const submit = () => {
      return copyPackage(
        copyData.reportId,
        props.dialog.dialogData.itemId
      ).then(res => {
        setSnackbar({
          text: 'Пакет успішно скопійовано',
          color: 'success',
        })
        return router.push({
          name: 'package-single',
          params: { id: res.id },
        })
      })
    }
    return {
      copyData,
      submit,
      v$,
    }
  },
}
</script>

<style scoped lang="scss"></style>

<template>
  <div>
    <v-tabs
      v-model="curTab"
      @update:model-value="
        $router.replace({
          name: $route.name,
          query: { section: $event as string },
        })
      ">
      <v-tab value="general-info">Загальна інформація</v-tab>
      <v-tab value="contacts">Контакти</v-tab>
      <v-tab value="employees">Співробітники</v-tab>
      <v-tab v-if="userData.isAdmin" value="tariffs">Тарифи</v-tab>
      <v-tab v-if="userData.isAdmin" value="integration-tokens">
        Токени інтеграції
      </v-tab>
      <v-tab v-if="userData.isAdmin" value="signature"> Підписання </v-tab>
    </v-tabs>
    <b-section-loader
      :loading="$loading.isLoading(['companySingle', 'getPackageDirectory'])">
      <v-window v-model="curTab">
        <v-window-item value="general-info" class="mt-3">
          <general-info v-model:company="company"></general-info>
        </v-window-item>
        <v-window-item value="contacts" class="mt-3">
          <contacts :company="company"></contacts>
        </v-window-item>
        <v-window-item value="employees" class="mt-3">
          <employees v-model:company="company"></employees>
        </v-window-item>
        <v-window-item value="tariffs" class="mt-3">
          <tariffs v-model:company="company"></tariffs>
        </v-window-item>
        <v-window-item value="integration-tokens">
          <integration-tokens v-model:company="company"></integration-tokens>
        </v-window-item>
        <v-window-item value="signature">
          <company-signature v-model:company="company"></company-signature>
        </v-window-item>
      </v-window>
    </b-section-loader>
  </div>
</template>

<script lang="ts">
import { cachedObject } from 'best-modules/utils'
import { getCompanySingle } from '@/request/company'
import { BSectionLoader } from 'best-modules/components'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { Company } from '@/utils/types'
import { useUser } from '@/store/user'
import { usePackageDirectory } from '@/store/package-directory'
import GeneralInfo from './tabs/GeneralInfo.vue'
import IntegrationTokens from './tabs/IntegrationTokens.vue'
import Contacts from './tabs/Contacts.vue'
import Employees from './tabs/Employees.vue'
import Tariffs from './tabs/Tariffs.vue'
import CompanySignature from '@/pages/companies/single/tabs/Signature.vue'

export default {
  name: 'CompanySingle',
  components: {
    CompanySignature,
    Tariffs,
    BSectionLoader,
    IntegrationTokens,
    GeneralInfo,
    Contacts,
    Employees,
  },
  setup() {
    const route = useRoute()
    const curTab = ref(route.query.section || 'general-info')
    const { userData } = useUser()

    const { fillDirectory } = usePackageDirectory()
    fillDirectory()

    const companyId = computed<string>(() => {
      if (route.name === 'user-company') {
        return userData.watch_company_user_role.companyId
      } else {
        return route.params.id as string
      }
    })

    const company = cachedObject({} as Company)

    getCompanySingle(companyId.value).then(res => {
      company.$set(res)
    })

    return {
      company,
      curTab,
      companyId,
      userData,
    }
  },
}
</script>

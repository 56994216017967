<template>
  <div>
    <v-row class="mt-2">
      <v-col cols="12" md="4" sm="12">
        <b-select
          v-model="localCompany.signatureAlgoId"
          label="Алгоритм підпису"
          :items="$directory.get('signatureAlgos', localCompany.signature_algo)"
          item-value="id"
          item-title="name"
          hide-details
          placeholder="оберіть алгоритм"
          :loading="$loading.isLoading('signatureAlgos')"
          @update:model-value="changeSignatureAlgo"
          @focus="$directory.fill('signatureAlgos')"></b-select>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { computed, PropType } from 'vue'
import { CachedObjectProxy } from 'best-modules/utils'
import { Company } from '@/utils/types'
import { updateCompanySignatureAlgo } from '@/request/company'
import { setSnackbar } from 'best-modules/plugins/index'

export default {
  name: 'CompanySignature',
  props: {
    company: {
      type: Object as PropType<CachedObjectProxy<Company>>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const localCompany = computed({
      get: () => props.company,
      set: val => emit('update:company', val),
    })

    const changeSignatureAlgo = () => {
      return updateCompanySignatureAlgo(
        localCompany.value.id,
        localCompany.value.signatureAlgoId
      ).then(res => {
        setSnackbar({
          text: 'Алгоритм підпису оновлено',
        })
        return res
      })
    }

    return { localCompany, changeSignatureAlgo }
  },
}
</script>

<style scoped lang="scss"></style>

import { defineStore } from 'pinia'
import { ref, Ref } from 'vue'
import { useRouter } from 'vue-router'
import moment from 'moment'
import { getAuthQr, getAuthToken } from '@/request/auth'

import { useUser } from '@/store/user'

import {
  setQrData,
  removeQrData,
  setToken,
  removeToken,
} from '@/store/auth/helpers'
import { setSnackbar } from 'best-modules/plugins'
import { getPersonName } from 'best-modules/utils'
import axios from '@/plugins/axios'

export interface QrData {
  url: string | null
  type?: string | null
  user: { id: string; name: string }
}

export const useAuth = defineStore('auth', () => {
  const router = useRouter()
  const { setUserData } = useUser()
  const qrData: Ref<QrData | null> = ref(null)

  const token: Ref<string | null> = ref(localStorage.getItem('token') || null)

  if (!token.value && localStorage.getItem('qrCode')) {
    const storageDate = localStorage.getItem('date')
    const curDate = moment().format('YYYY-MM-DD')
    if (storageDate !== curDate) {
      removeQrData()
    } else {
      qrData.value = {
        url: localStorage.getItem('qrCode') || null,
        user: {
          id: localStorage.getItem('userId'),
          name: localStorage.getItem('userName'),
        },
      }
    }
  }

  const qrDataHandler = (data?: QrData) => {
    if (data) {
      setQrData(data)
      qrData.value = data
    } else {
      removeQrData()
      qrData.value = null
    }
  }

  const tokenHandler = (t?: string) => {
    if (t) {
      setToken(t)
      token.value = t
    } else {
      removeToken()
      token.value = null
    }
  }

  const getQr = credentials => {
    return getAuthQr(credentials)
      .then(data => {
        if (data && data['2FA'] === false) {
          tokenHandler(data.token)
          qrDataHandler(null)
          return router.push({ name: 'home' })
        } else {
          qrDataHandler(data)
          return router.push({ name: 'auth-confirm' })
        }
      })
      .catch(e => {
        qrDataHandler(null)

        return Promise.reject(e)
      })
  }

  const getToken = (payload: { userId: string; code: string }) => {
    return getAuthToken(payload)
      .then(res => {
        tokenHandler(res.token)
        qrDataHandler(null)
        return router.push({ name: 'home' })
      })
      .catch(e => {
        tokenHandler(null)
        return Promise.reject(e)
      })
  }

  const logout = () => {
    return axios.post('/auth/logout').then(res => {
      const { removeUserData } = useUser()

      if (res?.status === 204) {
        removeUserData()
        tokenHandler(null)
        qrDataHandler(null)
        return router.push({ name: 'login' })
      }
      if (res.data.status === 200) {
        return router.push({ name: 'login' })
      }
      if (typeof res.data === 'object') {
        setUserData(res.data)
        setSnackbar({
          text: `Користувача змінено на ${getPersonName(res.data)}`,
        })
      }
    })
  }

  return { qrData, getQr, getToken, logout, token, qrDataHandler, tokenHandler }
})

<template>
  <div>
    <b-autocomplete
      v-model="company"
      class="mb-5"
      return-object
      :items="userData.company_user_roles || []"
      item-title="company.shortName"
      item-value="company.id"
      hide-details
      :error-messages="getErrorMessages(v$.company)"
      placeholder="Оберіть зі списку"
      @blur="v$.company.$touch()">
    </b-autocomplete>
  </div>
</template>

<script lang="ts">
import { useUser } from '@/store/user'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { ref } from 'vue'
import { getErrorMessages } from 'best-modules/utils'
import { changeCompany } from '@/request/company'
import { setSnackbar } from 'best-modules/plugins'
export default {
  methods: { getErrorMessages },
  setup() {
    const { userData } = useUser()

    const company = ref()

    const rules = {
      company: { required },
    }

    const v$ = useVuelidate(rules, { company })

    const submit = () => {
      return changeCompany(company.value.id).then(res => {
        if (res) {
          setSnackbar({ text: 'Компанію успішно змінено' })
        }

        userData.watchCompanyUserRoleId = res.watchCompanyUserRoleId
        userData.watch_company_user_role = res.watch_company_user_role
      })
    }

    return {
      userData,
      company,
      v$,
      submit,
    }
  },
}
</script>

<style scoped lang="scss"></style>

import { sendRequest } from 'best-modules/utils'
import type { SignDocumentDto, EncryptedSignature } from '@/utils/types'

const readSignature = (signatureData: EncryptedSignature, caId?: number) => {
  return sendRequest({
    name: 'readSignature',
    url: '/admin/electronicSignature/certificate',
    method: 'post',
    payload: {
      ...signatureData,
      caId,
    },
  })
}
const createSession = (signatureData: EncryptedSignature) => {
  return sendRequest({
    name: 'readSignature',
    url: '/admin/electronicSignature/certificate',
    method: 'post',
    payload: signatureData,
  })
}
const signDocument = (signatureData: SignDocumentDto) => {
  return sendRequest({
    name: 'signDocument',
    url: '/admin/electronicSignature/signature',
    method: 'post',
    payload: signatureData,
  })
}

const getPackageStatus = (signatureData: SignDocumentDto) => {
  return sendRequest({
    name: 'getPackageStatus',
    url: '/admin/nbu/getStatus',
    method: 'post',
    payload: signatureData,
  })
}

export { readSignature, signDocument, getPackageStatus, createSession }

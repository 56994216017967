import axios from '@/plugins/axios.js'
import FileDropper from './components/FileDropper.vue'
import InputFile from './components/InputFile.vue'
import DialogToolbar from './components/DialogToolbar.vue'
import Pagination from './components/Pagination.vue'
import { setSnackbar } from 'best-modules/plugins/index.js'
import { useAuth } from '@/store/auth/auth.js'
import router from '@/router/index.js'
import { socket } from '@/socket'
import { useUser } from '@/store/user'

export const bestModulesConfig = {
  socket,
  axios,
  templates: {
    BFileDropper: {
      component: FileDropper,
    },
    BFileInput: {
      component: InputFile,
    },
    BDialog: {
      toolbar: {
        component: DialogToolbar,
      },
      defaultWidth: '580px',
    },
    BActionButtons: {
      justify: 'start',
      reject: {
        variant: 'outlined',
        color: 'primary',
        density: 'default',
      },
      confirm: {
        variant: 'flat',
        color: 'primary',
        density: 'default',
      },
    },
    BPagination: {
      component: Pagination,
    },
    BLabel: {
      style: 'color: #7e92a2; margin-bottom: 8px',
    },
  },
  urls: { urlUploadFiles: () => '/admin/upload' },
  requestConfig: {
    rejectHandler: (err: any) => {
      if (err?.response?.data?.message) {
        setSnackbar({ text: err.response.data.message, color: 'error' })
      }
      if (err?.response?.status === 401) {
        const { tokenHandler } = useAuth()
        const { removeUserData } = useUser()
        tokenHandler(null)
        // qrDataHandler(null)
        removeUserData()

        return router.push({ name: 'login' })
      }

      return Promise.reject(err)
    },
  },
}

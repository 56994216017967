import forge from 'node-forge'
import { defineStore } from 'pinia'
import { getPublicKey } from '@/request/encrypt'

export const useEncrypt = defineStore('encrypt', () => {
  let publicKey: string

  async function encryptRSA(data: string): Promise<string> {
    if (!publicKey) {
      publicKey = await getPublicKey()
    }
    const pKey = forge.pki.publicKeyFromPem(publicKey)
    const encryptedData = pKey.encrypt(data, 'RSA-OAEP')
    return forge.util.encode64(encryptedData)
  }

  function fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.addEventListener('loadend', event => {
        const base64: string = event.target.result as string
        resolve(base64.split('base64,')[1])
      })
      reader.addEventListener('error', error => {
        reject(error)
      })
      reader.readAsDataURL(file)
    })
  }

  function arrayBufferToBase64(buffer: ArrayBuffer): string {
    const binary = new Uint8Array(buffer)
    return btoa(String.fromCharCode(...binary))
  }

  function generateSymmetricKey(): Uint8Array {
    // Генерация случайного симметричного ключа (длина ключа зависит от используемого алгоритма)
    const keyLength = 128 // Длина ключа AES-128
    return crypto.getRandomValues(new Uint8Array(keyLength / 8))
  }

  function generateIV(): Uint8Array {
    return crypto.getRandomValues(new Uint8Array(12))
  }

  async function encryptWithSymmetricKey(
    data: string,
    symKey: Uint8Array,
    iv: Uint8Array
  ): Promise<string> {
    const key = await window.crypto.subtle.importKey(
      'raw',
      symKey,
      { name: 'AES-GCM' },
      false,
      ['encrypt']
    )

    const encrypted = await window.crypto.subtle.encrypt(
      {
        name: 'AES-GCM',
        iv, // Использование сгенерированного IV
        tagLength: 128,
      },
      key,
      new TextEncoder().encode(data)
    )

    const encryptedData = new Uint8Array(encrypted)

    return btoa(String.fromCharCode(...encryptedData))
  }

  return {
    generateSymmetricKey,
    generateIV,
    encryptWithSymmetricKey,
    fileToBase64,
    arrayBufferToBase64,
    encryptRSA,
  }
})

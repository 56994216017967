import { useEncrypt } from '@/store/encrypt'

export function useEncryptPrivateKey() {
  const encryptPrivateKey = async (signature: string, password: string) => {
    const {
      generateSymmetricKey,
      generateIV,
      encryptWithSymmetricKey,
      arrayBufferToBase64,
      encryptRSA,
    } = useEncrypt()
    const symKey = generateSymmetricKey()
    const iv = generateIV()

    const encryptedSignature = await encryptWithSymmetricKey(
      signature,
      symKey,
      iv
    )
    const encryptedPassword = await encryptWithSymmetricKey(
      password,
      symKey,
      iv
    )

    const ivBase64 = btoa(String.fromCharCode(...iv))
    const symKeyBase64 = arrayBufferToBase64(symKey)
    const encryptedSymKey = await encryptRSA(symKeyBase64)

    return {
      signature: encryptedSignature,
      password: encryptedPassword,
      symKey: encryptedSymKey,
      iv: ivBase64,
    }
  }

  return { encryptPrivateKey }
}

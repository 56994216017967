<template>
  <div>
    <div v-if="!isInitialized" class="lib-loading">
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
    <div class="mb-3">
      <b-select
        v-model="keyMedia"
        label="Носій ос. ключа"
        placeholder="оберіть носій ос. ключа"
        :items="keyMediaList"
        item-title="visibleName"
        return-object
        :error-messages="errorMessages"></b-select>
    </div>
  </div>
</template>

<script lang="ts">
import { initialize } from '@/utils/signature/signature'
import { computed, onMounted, PropType, ref } from 'vue'
import { getErrorMessages } from 'best-modules/utils'

export default {
  name: 'MediaKeyReader',
  emits: ['update:model-value'],
  props: {
    modelValue: {
      type: String as PropType<string>,
    },
    readonly: {
      type: Boolean,
    },
    errorMessages: {
      type: Array as PropType<string[]>,
    },
  },
  methods: { getErrorMessages },
  setup(props, { emit }) {
    const isInitialized = ref(false)
    const keyMediaList = ref<string[]>([])

    const keyMedia = computed({
      get() {
        return props.modelValue
      },
      set(val) {
        emit('update:model-value', val)
      },
    })

    onMounted(() => {
      initialize()
        .then((kMedias: string[]) => {
          keyMediaList.value = kMedias
        })
        .finally(() => {
          isInitialized.value = true
        })
    })

    return { isInitialized, keyMedia, keyMediaList }
  },
}
</script>

<style scoped lang="scss">
.lib-loading {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.07);
  z-index: 5;
}
</style>

import { defineStore } from 'pinia'
import { reactive, ref } from 'vue'
import { handleAsync } from 'best-modules/plugins'
import { getUserData as getUserDataRequest } from '@/request/user'
import type { User, UserRightName } from '@/utils/types'

export const useUser = defineStore('user', () => {
  const userData = reactive({}) as User
  const userAccessRights = ref<UserRightName[]>([])
  const isLoadedUser = ref<boolean>(false)

  const setUserData = (obj: User): void => {
    Object.assign(userData, obj)
    if (
      obj.watch_company_user_role &&
      obj.watch_company_user_role.repository &&
      obj.watch_company_user_role.repository.length
    ) {
      userAccessRights.value.push(...obj.watch_company_user_role.repository)
    }
  }

  const getUserData = (): Promise<void | User> => {
    return handleAsync('getUserData', () => {
      return getUserDataRequest().then(res => {
        setUserData(res)
        isLoadedUser.value = true

        return userData
      })
    })
  }

  const removeUserData = (): void => {
    Object.keys(userData).forEach(key => {
      delete userData[key]
    })
    isLoadedUser.value = false
  }

  return {
    getUserData,
    isLoadedUser,
    userData,
    removeUserData,
    setUserData,
    userAccessRights,
  }
})

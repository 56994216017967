<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems" class="px-0 pt-0">
      <template #prepend>
        <v-icon class="mr-2" @click="$router.push({ name: 'home' })"
          >mdi-home
        </v-icon>
      </template>
      <template #divider>
        <v-icon icon="mdi-chevron-right"></v-icon>
      </template>
      <template #item="{ item }">
        <v-breadcrumbs-item
          v-if="item.title"
          :title="item.title"
          :class="{
            'cursor-pointer': item.to,
          }"
          @click="item.to && $router.push(item.to)"></v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <div v-if="$route.name === 'home'" class="cursor-default">
      <h1>Ласкаво просимо в CR 2.0 NBU!</h1>
      <span class="text-blue-grey-lighten-2">
        Наш сервіс допоможе вам легко заповнити та передати щомісячні звіти до
        Кредитного реєстру.
      </span>
    </div>
  </div>
</template>

<script>
import { useBreadcrumb } from '@/plugins/breadcrumb.js'
import { computed } from 'vue'

export default {
  setup() {
    const { items } = useBreadcrumb()
    const home = { icon: 'mdi-home-circle', href: { name: 'home' } }
    const breadcrumbItems = computed(() => items.value || [])
    return { home, breadcrumbItems }
  },
}
</script>

<style lang="scss" scoped></style>

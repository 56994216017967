<template>
  <div>
    <div
      v-if="$loading.isLoading('parsePackage')"
      class="d-flex justify-center">
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
    <b-input-file
      v-show="!$loading.isLoading('parsePackage')"
      v-model="file"
      name-folder="packages"
      dropper
      :error-messages="getErrorMessages(v$.data)"
      :multiple="false"
      :accept="accept"
      type="paths"
      label="Оберіть файл звіту"
      @update:model-value="parsePackage">
      <template #dropper-label>
        <b>
          Перетягніть сюди файл або
          <u>
            оберіть його <br />
            на своєму носієві
          </u>
        </b>
      </template>
    </b-input-file>
    <div class="mt-5 text-center">
      <div v-if="isParseSuccess === true" class="text-success">
        Дані успішно зчитані
      </div>
      <div v-if="isParseSuccess === false" class="text-error">
        Помилка зчитування данних
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { BInputFile, FileAccept } from 'best-modules/components'
import { computed, PropType, reactive, ref } from 'vue'
import { Dialog } from 'best-modules/plugins/dialog/types'
import {
  createPackage,
  parsePackage as parsePackageRequest,
  updatePackage,
} from '@/request/report'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { getErrorMessages, download, getFile } from 'best-modules/utils'
import type { PackageCredentials } from '@/utils/types'
import { handleAsync } from 'best-modules/plugins'

export default {
  name: 'Package',
  components: { BInputFile },
  props: {
    dialog: {
      type: Object as PropType<Dialog<'Package'>>,
    },
  },
  methods: { getErrorMessages },
  setup(props) {
    const accept = computed<FileAccept[]>(() => {
      switch (props.dialog.dialogData.parseFrom) {
        case 'excel':
          return ['.xls', '.xlsx']
        case 'json':
          return ['.json']
        default:
          throw new Error(
            '[Package.vue] accept: ComputedRef - invalid parseFrom'
          )
      }
    })
    const packageData = reactive<PackageCredentials>({
      data: null,
      packageId: props.dialog.dialogData.packageId,
      reportId: props.dialog.dialogData.reportId,
    } as PackageCredentials)

    const rules = {
      data: { required },
    }

    const v$ = useVuelidate(rules, packageData)

    const isParseSuccess = ref<boolean | null>(null)
    const file = ref<string[] | null>(null)

    const submit = () => {
      if (props.dialog.dialogData.updatePackage) {
        return updatePackage(packageData.data[0].data, packageData.packageId)
      } else {
        const getCreateType = () => {
          switch (props.dialog.dialogData.parseFrom) {
            case 'excel':
              return 'E'
            case 'json':
              return 'J'
            default:
              throw new Error(
                '[Package.vue] getCreateType() - invalid parseFrom'
              )
          }
        }
        return createPackage({ ...packageData, createType: getCreateType() })
      }
    }

    const parsePackage = () => {
      if (file.value && file.value[0]) {
        handleAsync('parsePackage', () => {
          return parsePackageRequest(
            { file: file.value[0].substring(9) },
            props.dialog.dialogData.parseFrom
          )
            .then(async res => {
              if (res) {
                packageData.data = res.parsedData
                if (props.dialog.dialogData.parseFrom === 'excel') {
                  const path = (await getFile(res.file)) as string
                  await download(path, res.file.split('/').at(-1))
                }
                isParseSuccess.value = true
              }
            })
            .catch(e => {
              isParseSuccess.value = false
              file.value = null
              return Promise.reject(e)
            })
        })
      }
    }

    return {
      packageData,
      v$,
      submit,
      parsePackage,
      isParseSuccess,
      file,
      accept,
    }
  },
}
</script>

<style scoped></style>

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import { vuetify } from '@/plugins'
import { vMaska } from 'maska'
import BestModules from 'best-modules'
import DirectoryPlugin from './directory/index.ts'
import DialogPlugin from './plugins/dialog/index.ts'
import 'best-modules/assets/base.scss'
import './assets/scss/main.scss'
import { bestModulesConfig } from '@/plugins/best-modules/index.ts'
import * as Sentry from '@sentry/vue'
import { useUser } from '@/store/user.ts'

const app = createApp(App)

app.config.performance = true

app.directive('mask', vMaska)
app.use(router)
app.use(createPinia())
app.use(vuetify)
app.use(BestModules, bestModulesConfig)
app.use(DirectoryPlugin)
app.use(DialogPlugin)
console.log('npm package version', import.meta.env.PACKAGE_VERSION)
if (import.meta.env.PROD || location.origin.includes('localhost')) {
  Sentry.init({
    app,
    release: `credit_registry@${import.meta.env.PACKAGE_VERSION}`,
    enabled: true,
    dsn: 'https://68aa2f73cdd68342d6ce880a8c3e5812@o4507386165919744.ingest.de.sentry.io/4507390559846480',
    maxBreadcrumbs: 20,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.debugIntegration(),
    ],
    initialScope: scope => {
      const { userData } = useUser()
      scope.setUser(userData)
      return scope
    },

    // transactions
    tracesSampleRate: 0,
    // enableTracing: false,
    // tracePropagationTargets: ['https://credit.bestleasing.com.ua/'],

    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    async beforeSend(event, hint) {
      let originalException

      try {
        if (hint.originalException instanceof Promise) {
          originalException = await hint.originalException
        }
        if (
          typeof hint.originalException === 'object' &&
          hint.originalException !== null
        ) {
          originalException = hint.originalException
        }
      } catch (e) {
        originalException = e
      }

      const isServerError =
        typeof originalException === 'object' &&
        !!originalException.isAxios &&
        !!originalException.response &&
        !!originalException.response.status &&
        originalException.response.status !== 422
      const isHandledError = originalException.throwError === false

      console.groupCollapsed(
        `sentry - before send, isSend - ${!isServerError && !isHandledError}`
      )
      console.log('event', event)
      console.log('hint', hint)
      console.log('originalException', originalException)
      console.log('isServerError', isServerError)
      console.log('isHandledError', isHandledError)
      console.groupEnd()

      if (location.origin.includes('localhost')) {
        return null
      }
      if (isServerError || isHandledError) {
        return null
      } else {
        return event
      }
    },
  })
}

app.mount('#app')

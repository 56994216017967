<template>
  <div>
    <package-table-input-number
      v-model="accountInfo.acc_amount_info[0].sum"
      label="Сума"
      :coefficient="10 ** 2"
      :max-value="10 ** 13 - 1"></package-table-input-number>
    <b-input
      :model-value="
        accountInfo.fiai_amount_type
          ? directory.FIAI.getItem(accountInfo.fiai_amount_type)?.name ||
            'Недопустиме значення довідника'
          : accountInfo.fiai_amount_type
      "
      label="Тип суми"
      :readonly="true"
      placeholder="Не вказано">
      <template #append-inner>
        <v-btn
          v-tooltip="'Обрати з довідника'"
          icon="mdi-book-open-blank-variant-outline"
          variant="plain"
          color="grey"
          density="compact"
          @click="selectFromDirectory('fiai_amount_type')"></v-btn>
      </template>
    </b-input>
    <b-input
      :model-value="
        accountInfo.r030_currency
          ? directory.R030.getItem(accountInfo.r030_currency)?.name ||
            'Недопустиме значення довідника'
          : null
      "
      label="Валюта"
      :readonly="true"
      placeholder="Не вказано">
      <template #append-inner>
        <v-btn
          v-tooltip="'Обрати з довідника'"
          icon="mdi-book-open-blank-variant-outline"
          variant="plain"
          color="grey"
          density="compact"
          @click="selectFromDirectory('r030_currency')"></v-btn>
      </template>
    </b-input>
    <b-input
      :model-value="
        accountInfo.t020_account_type
          ? directory.T020.getItem(accountInfo.t020_account_type)?.name ||
            'Недопустиме значення довідника'
          : null
      "
      label="Характеристика рахунку"
      :readonly="true"
      placeholder="Не вказано">
      <template #append-inner>
        <v-btn
          v-tooltip="'Обрати з довідника'"
          icon="mdi-book-open-blank-variant-outline"
          variant="plain"
          color="grey"
          density="compact"
          @click="selectFromDirectory('t020_account_type')"></v-btn>
      </template>
    </b-input>
    <b-input
      v-model="accountInfo.add_parameter"
      label="Додатковий параметр"
      placeholder="Не вказано"
      :mask="addParameterMask"></b-input>
  </div>
</template>

<script lang="ts">
import { PropType, reactive } from 'vue'
import { Dialog } from 'best-modules/plugins'
import { usePackageDirectory } from '@/store/package-directory'
import { openDialog } from '@/plugins/dialog'
import { PackageTableValue } from '@/pages/report/package/package-table/types'
import { concatRegex, EN, NUMBER } from '@/pages/report/package/document/regExp'
import PackageTableInputNumber from '@/pages/report/package/package-table/input/PackageTableInputNumber.vue'

export default {
  name: 'PackageAccountInfo',
  components: { PackageTableInputNumber },
  props: {
    dialog: {
      type: Object as PropType<Dialog<'UpdateAccountInfo'>>,
    },
  },
  setup(props) {
    const { directory } = usePackageDirectory()
    const accountInfo = reactive({
      acc_amount_info: [{ sum: null }],
      t020_account_type: null,
      fiai_amount_type: null,
      r030_currency: null,
      add_parameter: null,
    })

    Object.assign(accountInfo, props.dialog.dialogData.accountInfo)

    const selectFromDirectory = (
      fieldName: 't020_account_type' | 'fiai_amount_type' | 'r030_currency'
    ) => {
      openDialog({
        name: 'PackageDirectory',
        dialogData: {
          hasF170: false,
          value: accountInfo[fieldName],
          col: {
            directory: fieldName.split('_')[0].toUpperCase(),
          },
        },
        params: {
          cb: (v: PackageTableValue) => {
            accountInfo[fieldName] = v
          },
        },
      })
    }

    const addParameterMask = {
      mask: '@'.repeat(50),
      tokens: {
        '@': {
          pattern: concatRegex(NUMBER, EN),
        },
      },
    }

    const submit = () => {
      return Promise.resolve(accountInfo)
    }

    return {
      accountInfo,
      submit,
      directory,
      selectFromDirectory,
      addParameterMask,
    }
  },
}
</script>

<style scoped lang="scss"></style>

<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-autocomplete
          v-model="newUser.userId"
          :error-messages="getErrorMessages(v$.userId)"
          :items="allUsers"
          autocompleteStyle="width: 100%"
          :item-title="userCustomName"
          item-value="id"
          placeholder="Оберіть користувача"
          @blur="v$.userId.$touch()" />
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { getErrorMessages, getPersonName } from 'best-modules/utils'
import { fillDirectory, getDirectory } from '@/directory'
import { computed, reactive } from 'vue'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { setSnackbar } from 'best-modules/plugins'
import { changeUser } from '@/request/user'
import { useUser } from '@/store/user'

export default {
  name: 'ChangeUser',
  methods: { getPersonName, getErrorMessages },

  async setup() {
    const { userData, setUserData } = useUser()

    await fillDirectory('userList')

    const currentUser = computed(() => userData)

    const allUsers = computed(() => {
      return getDirectory('userList').filter(u => {
        return u.id !== currentUser.value.id
      })
    })

    const userCustomName = user => {
      if (user && user.company_user_roles && user.company_user_roles.length) {
        return `${getPersonName(user)} (${user?.company_user_roles[0]?.company?.shortName})`
      } else return getPersonName(user)
    }

    const newUser = reactive({
      userId: null,
    })

    const rules = computed(() => {
      return {
        userId: { required },
      }
    })
    const v$ = useVuelidate(rules, newUser)

    const submit = () => {
      v$.value.$validate()
      if (!v$.value.$error) {
        return changeUser(newUser.userId).then(res => {
          setUserData(res)
          setSnackbar({ text: 'Користувача успішно змінено' })

          return res
        })
      }
    }

    return {
      v$,
      submit,
      newUser,
      allUsers,
      userCustomName,
    }
  },
}
</script>

<style scoped lang="scss"></style>

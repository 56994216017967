<template>
  <div>
    <div class="d-flex justify-end mb-3">
      <v-btn @click="createTariff">
        <span>Додати тариф</span>
        <v-icon class="ml-2">mdi-plus</v-icon>
      </v-btn>
    </div>
    <v-data-table
      :headers="companyTariffsHeaders"
      :items="company.company_tariffs"
      item-value="id">
      <template #item.year="{ item }">
        {{ new Period(item.period).year }}
      </template>
      <template #item.month="{ item }">
        {{ new Period(item.period).getMonth() }}
      </template>
      <template #item.successPackagesCount="{ item }">
        <span>
          {{ rankSpacing(item.successPackagesCount) }}
        </span>
      </template>
      <template #item.limit="{ item }">
        <span>
          {{ item.tariffId === 2 ? 'Безліміт' : 10 }}
        </span>
      </template>

      <template #item.actions="{ item }">
        <b-action-menu
          :actions="[
            {
              title: 'Редагувати',
              icon: 'mdi-pencil',
              action: () => updateTariff(item),
            },
            {
              title: 'Видалити',
              icon: 'mdi-delete-outline',
              action: () => deleteTariff(item),
            },
          ]"></b-action-menu>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue'
import { CachedObjectProxy, rankSpacing } from 'best-modules/utils'
import { Company } from '@/utils/types'
import { companyTariffsHeaders } from '@/utils/tableHeaders'
import { openDialog } from '@/plugins/dialog'
import { Period } from '@/utils/helpers'
import { BActionMenu } from 'best-modules/components'
import { CompanyTariff } from '@/utils/types'
import { deleteCompanyTariff } from '@/request/company'
import { confirm } from 'best-modules/plugins'

export default {
  name: 'Tariffs',
  components: { BActionMenu },
  props: {
    company: {
      type: Object as PropType<CachedObjectProxy<Company>>,
      required: true,
    },
  },
  data: () => {
    return { companyTariffsHeaders, Period }
  },
  methods: { rankSpacing },
  setup(props) {
    const createTariff = () => {
      return openDialog({
        name: 'CompanyTariff',
        action: 'create',
        dialogData: {
          companyId: props.company.id,
        },
        params: {
          cb: (updatedCompany: Company) => {
            props.company.$set(updatedCompany)
          },
        },
      })
    }
    const updateTariff = (tariff: CompanyTariff) => {
      return openDialog({
        name: 'CompanyTariff',
        action: 'update',
        dialogData: {
          companyId: props.company.id,
          companyTariffId: tariff.id,
          tariffId: tariff.tariffId,
          period: tariff.period,
        },
        params: {
          cb: (updatedCompany: Company) => {
            props.company.$set(updatedCompany)
          },
        },
      })
    }
    const deleteTariff = (tariff: CompanyTariff) => {
      const tariffPeriod = `${new Period(tariff.period).getMonth()} ${new Period(tariff.period).year}`

      return confirm({
        text: `Ви впевнені що хочете видалити тариф ${tariff.tariff.name} за ${tariffPeriod}?`,
      }).then(() => {
        deleteCompanyTariff(tariff.id).then(updatedCompany => {
          props.company.$set(updatedCompany)
        })
      })
    }

    return { createTariff, updateTariff, deleteTariff }
  },
}
</script>

<style scoped lang="scss"></style>

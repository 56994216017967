import { sendRequest } from 'best-modules/utils'

const getAuthQr = (credentials: { phone: string; password: string }) => {
  return sendRequest({
    name: 'getAuthQr',
    url: '/auth/firstLogin',
    method: 'post',
    payload: credentials,
  })
}

const getAuthToken = (payload: { userId: string; code: string }) => {
  return sendRequest({
    name: 'getAuthToken',
    url: '/auth/secondLogin',
    method: 'post',
    payload: payload,
  })
}
const sendSms = (userId: string) => {
  return sendRequest({
    name: 'sendSms',
    url: `/auth/sendSms/${userId}`,
  })
}
// const logout = () => {
//   return sendRequest({
//     name: 'logout',
//     url: '/auth/logout',
//     method: 'post',
//   })
// }
const resetPasswordSendCode = (phone: string) => {
  return sendRequest({
    name: 'resetPasswordSendCode',
    url: '/auth/resetPassword/sendCode',
    method: 'post',
    payload: { phone },
  })
}
const resetPassword = (resetData: {
  verificationCode: string
  userId: string
  password: string
}) => {
  return sendRequest({
    name: 'resetPassword',
    url: '/auth/resetPassword',
    method: 'post',
    payload: resetData,
  })
}

export {
  getAuthQr,
  getAuthToken,
  sendSms,
  // logout,
  resetPassword,
  resetPasswordSendCode,
}

import { ValidationErrors } from './validation/types'
import { InjectionKey, ComputedRef, Ref } from 'vue'
import { PackageData } from '@/utils/types'
import { PackageTableCol } from './package-table/types'

const validationErrorsKey: InjectionKey<ValidationErrors> =
  Symbol('validationErrors')
const isValidatedKey: InjectionKey<Ref<boolean>> = Symbol('isValidatedKey')
const packageDataKey: InjectionKey<ComputedRef<PackageData>> =
  Symbol('packageData')
const updatePackageDataKey: InjectionKey<
  (_path: PackageTableCol['path'], _value: any) => void
> = Symbol('updatePackageData')
const schemaKey: InjectionKey<
  Omit<PackageData, 'person_full' | 'person_short'>
> = Symbol('schema')
const readonlyKey: InjectionKey<ComputedRef<boolean>> = Symbol('readonly')

export {
  validationErrorsKey,
  packageDataKey,
  updatePackageDataKey,
  schemaKey,
  readonlyKey,
  isValidatedKey,
}

<template>
  <div>
    <sign-local
      v-if="['file', 'media'].includes(keyType)"
      :key-type="keyType"
      @cancel="keyType = null"></sign-local>
    <sign-cloud v-if="keyType === 'cloud'"></sign-cloud>
    <key-type-select v-if="!keyType" v-model="keyType"></key-type-select>
  </div>
</template>

<script lang="ts">
import SignLocal from './sign-local/SignLocal.vue'
import SignCloud from './sign-cloud/SignCloud.vue'
import KeyTypeSelect from './KeyTypeSelect.vue'
import { KeyType } from './types'
import { ref, provide } from 'vue'
import { dialogKey } from './injectionKeys'

export default {
  name: 'SignIndex',
  components: { KeyTypeSelect, SignLocal, SignCloud },
  props: {
    dialog: {
      type: Object,
    },
  },
  setup(props) {
    const keyType = ref<KeyType>(null)
    provide(dialogKey, props.dialog)

    return { keyType }
  },
}
</script>

<style scoped lang="scss"></style>

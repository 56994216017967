<template>
  <div class="mb-5">{{ dialog.dialogData.confirmText }}</div>
</template>

<script lang="ts">
export default {
  props: {
    dialog: { type: Object },
  },
  setup() {
    const submit = () => {
      return Promise.resolve('success')
    }

    return { submit }
  },
}
</script>

<style scoped lang="scss"></style>

<template>
  <div>
    <company-list v-if="$route.name === 'company-list'"></company-list>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import CompanyList from './CompanyList.vue'
export default {
  name: 'CompanyIndex',
  components: { CompanyList },
}
</script>

<template>
  <v-row style="font-size: 13px">
    <v-col v-if="keyInfo?.fullCertificate?.subjOrg" cols="12" class="pb-1 pt-0">
      <div class="text-disabled">Назва компанії</div>
      <div class="d-flex align-center">
        <span class="d-block mr-1">{{
          keyInfo?.fullCertificate?.subjOrg
        }}</span>
        <span v-if="keyInfo?.fullCertificate?.subjEDRPOUCode" class="d-block">
          ({{ keyInfo?.fullCertificate?.subjEDRPOUCode }})</span
        >
      </div>
    </v-col>

    <v-col
      v-if="keyInfo?.fullCertificate?.subjFullName"
      cols="12"
      class="pb-0 pt-0">
      <div class="text-disabled">ПІБ</div>
      <div class="d-flex align-center">
        <span class="mr-1">{{ keyInfo?.fullCertificate?.subjFullName }}</span>
        <span v-if="keyInfo?.fullCertificate?.subjDRFOCode"
          >({{ keyInfo?.fullCertificate?.subjDRFOCode }})</span
        >
      </div>
    </v-col>

    <v-col cols="6" class="pb-0 pt-0">
      <div class="text-disabled">Орган що видав</div>
      <div>{{ keyInfo?.fullCertificate?.issuerCN }}</div>
    </v-col>

    <v-col cols="12" class="pb-0 pt-0">
      <div class="text-disabled">Номер сертифікату</div>
      <div class="d-flex align-center">
        <span class="mr-1">{{ keyInfo?.fullCertificate?.serial }}</span>
        <span
          v-if="
            keyInfo?.certificate?.certBeginTime ||
            keyInfo?.fullCertificate?.start ||
            keyInfo?.fullCertificate?.certBeginTime
          ">
          (
          {{
            new CustomDate(
              keyInfo?.certificate?.start?.replace('T', ' ') ||
                keyInfo?.certificate?.certBeginTime?.replace('T', ' ') ||
                keyInfo?.fullCertificate?.start?.replace('T', ' ') ||
                keyInfo?.fullCertificate?.certBeginTime?.replace('T', ' ') ||
                ''
            ).toString({
              time: false,
            })
          }}
        </span>
        <span> - </span>
        <span
          v-if="
            keyInfo?.certificate?.certEndTime ||
            keyInfo?.fullCertificate?.end ||
            keyInfo?.fullCertificate?.certEndTime
          ">
          {{
            new CustomDate(
              keyInfo?.certificate?.end?.replace('T', ' ') ||
                keyInfo?.certificate?.certEndTime?.replace('T', ' ') ||
                keyInfo?.fullCertificate?.end?.replace('T', ' ') ||
                keyInfo?.fullCertificate?.certBeginTime?.replace('T', ' ')
            ).toString({
              time: false,
            })
          }}
          )
        </span>
      </div>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { CustomDate } from 'best-modules/utils'
export default {
  name: 'SignatoryData',
  props: {
    keyInfo: {
      type: Object,
    },
  },
  data() {
    return {
      CustomDate,
    }
  },
}
</script>

<style scoped lang="scss"></style>

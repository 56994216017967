<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 37 37"
    fill="none">
    <mask
      id="mask0_1294_2245"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="37"
      height="37">
      <rect width="37" height="37" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_1294_2245)">
      <path
        d="M24.975 31.6042L29.2917 27.2875V30.8333H30.8333V24.6667H24.6667V26.2083H28.2125L23.8958 30.525L24.975 31.6042ZM27.75 35.4583C25.6174 35.4583 23.7995 34.7068 22.2964 33.2036C20.7932 31.7005 20.0417 29.8826 20.0417 27.75C20.0417 25.6174 20.7932 23.7995 22.2964 22.2964C23.7995 20.7932 25.6174 20.0417 27.75 20.0417C29.8826 20.0417 31.7005 20.7932 33.2036 22.2964C34.7068 23.7995 35.4583 25.6174 35.4583 27.75C35.4583 29.8826 34.7068 31.7005 33.2036 33.2036C31.7005 34.7068 29.8826 35.4583 27.75 35.4583ZM10.7917 13.875H26.2083V10.7917H10.7917V13.875ZM17.999 32.375H7.70833C6.86042 32.375 6.13455 32.0731 5.53073 31.4693C4.92691 30.8655 4.625 30.1396 4.625 29.2917V7.70833C4.625 6.86042 4.92691 6.13455 5.53073 5.53073C6.13455 4.92691 6.86042 4.625 7.70833 4.625H29.2917C30.1396 4.625 30.8655 4.92691 31.4693 5.53073C32.0731 6.13455 32.375 6.86042 32.375 7.70833V18.0375C31.6299 17.6778 30.8783 17.408 30.1203 17.2281C29.3623 17.0483 28.5722 16.9583 27.75 16.9583C27.4674 16.9583 27.204 16.9648 26.9599 16.9776C26.7158 16.9905 26.4653 17.0226 26.2083 17.074V16.9583H10.7917V20.0417H20.2344C19.7719 20.4785 19.3543 20.9538 18.9818 21.4677C18.6092 21.9816 18.2816 22.534 17.999 23.125H10.7917V26.2083H17.074C17.0226 26.4653 16.9905 26.7158 16.9776 26.9599C16.9648 27.204 16.9583 27.4674 16.9583 27.75C16.9583 28.5979 17.0354 29.388 17.1896 30.1203C17.3438 30.8526 17.6135 31.6042 17.999 32.375Z"
        fill="#514EF3" />
    </g>
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss"></style>

<template>
  <div class="h-screen">
    <router-view></router-view>
    <b-snackbar />
    <b-confirm />
    <b-dialog></b-dialog>
  </div>
</template>

<script>
import { BDialog, BSnackbar, BConfirm } from 'best-modules/plugins'
import { onErrorCaptured, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useBreadcrumb } from '@/plugins/breadcrumb.js'

export default {
  name: 'App',
  components: {
    BSnackbar,
    BDialog,
    BConfirm,
  },
  setup() {
    const route = useRoute()
    const breadcrumb = useBreadcrumb()

    onErrorCaptured(err => {
      console.info(err)
    })

    // set breadcrumb
    watch(
      () => route.matched.find(r => r.meta.tabList)?.name || route.name,
      () => {
        breadcrumb.setRoutes(route.matched)
      }
    )
    // set breadcrumb part
    watch(
      () => route.matched.find(r => r.meta.breadcrumbIdx),
      r => {
        if (r) {
          breadcrumb.set([{ title: r.meta.title, index: r.meta.breadcrumbIdx }])
        }
      }
    )
  },
}
</script>

<template>
  <div>sign cloud</div>
</template>

<script lang="ts">
export default {
  name: 'SignCloud',
}
</script>

<style scoped lang="scss"></style>

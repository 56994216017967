import { defineStore } from 'pinia'
import { getPackageDirectory } from '@/request/report'
import { PackageDirectory } from './types'
import { Collection } from 'best-modules/utils'
import { reactive } from 'vue'

const usePackageDirectory = defineStore('packageDirectory', () => {
  const directory = reactive<PackageDirectory>({} as PackageDirectory)

  const fillDirectory = async (): Promise<void> => {
    if (!Object.values(directory).filter(Boolean).length) {
      await getPackageDirectory()
        .then(directoryList => {
          for (const directoryName in directoryList) {
            directory[directoryName] = new Collection(
              'code',
              directoryList[directoryName]
            )
          }
        })
        .catch(() => {
          for (const directoryName in directory) {
            delete directory[directoryName]
          }
        })
    }
  }

  return { directory, fillDirectory }
})

export { usePackageDirectory }

<template>
  <div>
    <div class="d-flex justify-end mb-3">
      <v-btn
        v-if="userAccessRights.includes('companyUserRole.create')"
        @click="createUserRole">
        <span>Додати користувача</span>
        <v-icon class="ml-2">mdi-plus</v-icon>
      </v-btn>
    </div>
    <v-data-table
      :headers="companyEmployeesHeaders"
      :items-per-page="-1"
      :items="company.company_user_roles">
      <template #bottom> </template>
      <template #[`item.user`]="{ item }">
        {{ getPersonName(item.user || item, { initials: true }) }}
      </template>
      <template #[`item.role`]="{ item }">
        <span>{{ item.role }}</span>
      </template>
      <template #[`item.actions`]="{ item }">
        <b-action-menu
          :actions="[
            {
              title: 'Видалити',
              icon: 'mdi-delete-outline',
              disabled: !userAccessRights.includes('companyUserRole.delete'),
              action: () => deleteCompanyEmployee(item.id),
            },
          ]"></b-action-menu>
      </template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { useUser } from '@/store/user'
import { computed, PropType } from 'vue'
import { CachedObjectProxy, getPersonName } from 'best-modules/utils'
import { Company } from '@/utils/types'
import { openDialog } from '@/plugins/dialog'
import { setSnackbar } from 'best-modules/plugins'
import { companyEmployeesHeaders } from '@/utils/tableHeaders'
import { deleteUserCompanyRole } from '@/request/company'
import { BActionMenu } from 'best-modules/components'

export default {
  name: 'Employees',
  components: { BActionMenu },
  emits: ['update:company'],
  props: {
    company: {
      type: Object as PropType<CachedObjectProxy<Company>>,
      required: true,
    },
  },
  data: () => {
    return { companyEmployeesHeaders }
  },
  methods: { getPersonName },
  setup(props, { emit }) {
    const { userAccessRights } = storeToRefs(useUser())

    const localCompany = computed({
      get: () => props.company,
      set: val => emit('update:company', val),
    })

    const createUserRole = () => {
      openDialog({
        name: 'User',
        dialogData: {
          companyId: localCompany.value.id,
          isCreateCompanyRole: true,
        },
        params: {
          cb: item => {
            setSnackbar({ text: 'Співробітника додано', color: 'success' })
            localCompany.value.company_user_roles.push(item)
          },
        },
      })
    }

    const deleteCompanyEmployee = id => {
      return deleteUserCompanyRole(id).then(() => {
        setSnackbar({ text: 'Співробітника видалено', color: 'success' })
        localCompany.value.company_user_roles =
          localCompany.value.company_user_roles.filter(item => item.id !== id)
      })
    }

    return {
      userAccessRights,
      localCompany,
      createUserRole,
      deleteCompanyEmployee,
    }
  },
}
</script>

<style scoped lang="scss"></style>

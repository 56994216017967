<template>
  <div>
    <signature :signature="package?.electronic_signature"></signature>
  </div>
</template>

<script lang="ts">
import Signature from './Signature.vue'

export default {
  name: 'SignatureStatus',
  components: { Signature },
  props: {
    package: {
      type: Object,
    },
  },
}
</script>

<style scoped lang="scss"></style>

import { ValidationErrors, ValidationMsg, ValidationMsgLike } from './types'
import { validationErrorsKey, isValidatedKey } from '../injectionKeys'
import { concat, forIn, uniq } from 'lodash'
import { provide, reactive, ref } from 'vue'
import { packageValidation } from '@/request/report'
import { handleAsync, setSnackbar } from 'best-modules/plugins'
import { openDialog } from '@/plugins/dialog'

export function usePackageValidation(packageId: string) {
  const isValidated = ref<boolean>(false)
  const validationErrors = reactive<ValidationErrors>({})
  provide(validationErrorsKey, validationErrors)
  provide(isValidatedKey, isValidated)

  const isValidationMsgLike = (value: unknown): value is ValidationMsgLike => {
    const isValidArr = (v: any[]): v is ValidationMsg => {
      return v.reduce((acc: boolean, item: any) => {
        switch (true) {
          case !acc:
            return false
          case typeof item === 'string':
            return true
          case Array.isArray(item):
            return item.reduce((a: boolean, i: any) => {
              return a ? typeof i === 'string' : false
            }, true)
          default:
            return false
        }
      }, true)
    }
    if (Array.isArray(value)) {
      return !!value.length && isValidArr(value)
    } else {
      if (
        typeof value === 'object' &&
        value !== null &&
        ('value' in value || 'f170' in value)
      ) {
        if ('value' in value) {
          return (
            Array.isArray(value.value) &&
            !!value.value.length &&
            isValidArr(value.value)
          )
        }
        if ('f170' in value) {
          return (
            Array.isArray(value.f170) &&
            !!value.f170.length &&
            isValidArr(value.f170)
          )
        }
      }
    }
    return false
  }

  const getValidationMsg = (value: ValidationMsgLike): ValidationMsg => {
    const arr: ValidationMsg | ValidationMsg[] = Array.isArray(value)
      ? (value as ValidationMsg | ValidationMsg[])
      : ('value' in value &&
          (value.value as ValidationMsg | ValidationMsg[])) ||
        ('f170' in value && (value.f170 as ValidationMsg))
    const isValidationMsg = (v: ValidationMsgLike): v is ValidationMsg => {
      return (
        Array.isArray(v) &&
        v.reduce((acc, item) => {
          return acc ? typeof item === 'string' : false
        }, false)
      )
    }
    if (isValidationMsg(arr)) {
      return arr
    } else {
      return uniq(concat(...arr))
    }
  }

  const fillValidationErrors = (v: any, prefix?: string) => {
    forIn(v, async (value, key) => {
      const k = prefix ? `${prefix}.${key}` : key
      if (typeof value === 'string') {
        return
      }
      if (isValidationMsgLike(value)) {
        validationErrors[k] = getValidationMsg(value)
      } else {
        fillValidationErrors(value, k)
      }
    })
  }

  const validationErrorsDialog = () => {
    if (validationErrors) {
      openDialog({
        name: 'PackageValidationErrors',
        dialogData: { validationErrors, packageId },
      })
    }
  }

  const validateFile = () => {
    return handleAsync('validateFile', async () => {
      isValidated.value = true
      const res = await packageValidation(packageId)
      Object.keys(validationErrors).forEach(k => {
        delete validationErrors[k]
      })
      setSnackbar({
        text: 'Пакет провалідовано',
        color: 'success',
      })
      if (!res.reportId) {
        console.time('validate')
        fillValidationErrors(res.data)
        validationErrorsDialog()
        console.timeEnd('validate')
      }

      return res
    })
  }

  return { validateFile, validationErrors }
}

<template>
  <div class="cursor-default">
    <div>
      <v-row>
        <v-col
          v-for="(item, idx) in cards"
          :key="idx"
          cols="12"
          md="3"
          sm="12"
          :class="idx !== 0 && !mobile ? 'pl-0' : null"
          class="mt-3 gap-3">
          <CardItem
            :title="item.title"
            :color="item.color"
            :component="item.component"
            :description="item.description" />
        </v-col>
      </v-row>
      <v-card class="mt-3">
        <v-card-text>
          <div class="mt-3">
            <h3 class="font-weight-bold mb-2">Мої звіти</h3>
            <span class="text-blue-grey-lighten-2">
              Кількість поданих вами звітів
            </span>
          </div>
          <div class="mt-3 d-flex align-center gap-3">
            <div>
              <v-chip color="grey" rounded="circle" size="large">
                <PackageCountIcon />
              </v-chip>
            </div>
            <div>
              <h4
                v-if="
                  userData.watch_company_user_role.company
                    ?.current_company_tariff
                "
                class="font-weight-bold">
                Всього:
                {{
                  userData?.watch_company_user_role.company
                    ?.current_company_tariff?.successPackagesCount || 0
                }}
              </h4>
              <span v-else class="text-disabled">Тариф відсутній</span>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import CardItem from '@/pages/home/CardItem.vue'
import { ref } from 'vue'
import FileIcon from '@/assets/images/FileIcon.vue'
import IntegrationIcon from '@/assets/images/IntegrationIcon.vue'
import CheckCircleIcon from '@/assets/images/CheckCircleIcon.vue'
import CardIcon from '@/assets/images/CardIcon.vue'
import PackageCountIcon from '@/assets/images/PackageCountIcon.vue'
import { useUser } from '@/store/user.ts'
import { useDisplay } from 'vuetify'

export default {
  components: { PackageCountIcon, CardItem },
  setup() {
    const { userData } = useUser()
    const { mobile } = useDisplay()
    const cards = ref([
      {
        title: 'Швидке заповнення',
        component: CardIcon,
        description:
          'Ми підготували всі дані в зручні таблиці – вам залишається лише заповнити їх.',
        color: 'blue',
      },
      {
        title: 'Валідація даних',
        component: CheckCircleIcon,
        description:
          'Заповніть поля, а ми перевіримо та покажемо, якщо є помилки.',
        color: 'green',
      },
      {
        title: 'Генерація звіту',
        component: FileIcon,
        description:
          'Ми автоматично згенеруємо звіт і відправимо його до Кредитного реєстру.',
        color: 'yellow',
      },
      {
        title: 'Зв’язок з ERP',
        component: IntegrationIcon,
        description:
          'Працюйте у звичному середовищі – ми інтегруємось з вашою ERP.',
        color: 'red',
      },
    ])
    return {
      cards,
      userData,
      mobile,
    }
  },
}
</script>

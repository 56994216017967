<template>
  <div>
    <b-input
      v-model="company.fullName"
      label="Повна назва згідно ЄДР"
      placeholder="Введіть текст"
      :error-messages="getErrorMessages(v$.fullName)"
      @blur="v$.fullName.$touch()"></b-input>
    <b-input
      v-model="company.shortName"
      label="Коротка назва"
      placeholder="Введіть текст"
      :error-messages="getErrorMessages(v$.shortName)"
      @blur="v$.shortName.$touch()"></b-input>
    <b-autocomplete
      v-model="company.countryId"
      :items="[...(directory.K040 || [])].filter(d => !d.closeDate)"
      label="Країна"
      item-value="id"
      placeholder="Оберіть зі списку"
      :error-messages="getErrorMessages(v$.countryId)"
      @blur="v$.countryId.$touch()"></b-autocomplete>
    <b-input
      v-model="company.edrpou"
      label="ЄДРПОУ"
      mask="########"
      placeholder="Введіть ЄДРПОУ"
      :error-messages="getErrorMessages(v$.edrpou)"
      @blur="v$.edrpou.$touch()"></b-input>
    <b-select
      v-model="company.typeId"
      :items="$directory.get('companyTypes')"
      label="Тип"
      item-value="id"
      item-title="name"
      placeholder="Оберіть зі списку"
      :loading="$loading.isLoading('companyTypes')"
      :error-messages="getErrorMessages(v$.typeId)"
      @focus="$directory.fill('companyTypes')"
      @blur="v$.typeId.$touch()"></b-select>
    <b-autocomplete
      v-model="company.sectorEconomyId"
      :items="[...(directory.K070 || [])].filter(d => !d.closeDate)"
      label="Інституційний сектор економіки"
      item-value="id"
      placeholder="Оберіть зі списку"
      :error-messages="getErrorMessages(v$.sectorEconomyId)"
      @blur="v$.sectorEconomyId.$touch()"></b-autocomplete>
    <b-autocomplete
      v-model="company.typeEconomyActivityId"
      :items="[...(directory.K110 || [])].filter(d => !d.closeDate)"
      label="Вид економічної діяльності особи"
      item-title="name"
      item-value="id"
      placeholder="Оберіть зі списку"
      :error-messages="getErrorMessages(v$.typeEconomyActivityId)"
      @blur="v$.typeEconomyActivityId.$touch()"></b-autocomplete>
    <b-autocomplete
      v-model="company.NBUPersonTypeId"
      :items="[...(directory.F082 || [])].filter(d => !d.closeDate)"
      label="Тип особи НБУ"
      item-value="id"
      placeholder="Оберіть зі списку"
      :error-messages="getErrorMessages(v$.NBUPersonTypeId)"
      @blur="v$.NBUPersonTypeId.$touch()"></b-autocomplete>
    <b-autocomplete
      v-model="company.NBURegCodeTypeId"
      :items="[...(directory.K021 || [])].filter(d => !d.closeDate)"
      label="Тип ЄДРПОУ НБУ"
      item-value="id"
      placeholder="Оберіть зі списку"
      :error-messages="getErrorMessages(v$.NBURegCodeTypeId)"
      @blur="v$.NBURegCodeTypeId.$touch()"></b-autocomplete>
  </div>
</template>

<script lang="ts">
import { reactive } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import { createCompany } from '@/request/company'
import { getErrorMessages } from 'best-modules/utils'
import type { CompanyCredentials } from '@/utils/types'
import { usePackageDirectory } from '@/store/package-directory'
import { BSelect } from 'best-modules/components'

export default {
  name: 'Company',
  components: { BSelect },
  methods: { getErrorMessages },
  setup() {
    const { directory, fillDirectory } = usePackageDirectory()

    fillDirectory()

    const company = reactive<CompanyCredentials>({
      shortName: null,
      fullName: null,
      edrpou: null,
      typeId: null,
      sectorEconomyId: null,
      typeEconomyActivityId: null,
      countryId: null,
      NBUPersonTypeId: null,
      NBURegCodeTypeId: null,
    })
    const rules = {
      shortName: { required },
      fullName: { required },
      edrpou: { required, minLength: minLength(8) },
      typeId: { required },
      sectorEconomyId: { required },
      typeEconomyActivityId: { required },
      countryId: { required },
      NBUPersonTypeId: { required },
      NBURegCodeTypeId: { required },
    }
    const v$ = useVuelidate(rules, company)

    const submit = () => {
      return createCompany(company)
    }

    return { company, v$, submit, directory }
  },
}
</script>

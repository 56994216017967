<template>
  <div>
    <v-row
      :style="{
        margin: mobile ? '0 auto' : 'auto',
        height: mobile ? '100vh' : 'auto',
      }">
      <v-col
        cols="12"
        md="6"
        sm="12"
        class="d-flex align-center justify-center pt-0 pb-0">
        <div :style="{ width: mobile ? '90%' : '360px', minHeight: '300px' }">
          <router-view></router-view>
        </div>
      </v-col>
      <v-col v-if="!mobile" cols="12" md="6" sm="12" class="pt-0 pb-0">
        <div class="background">
          <div class="circle top"></div>
          <div>
            <h1 class="mb-3">CR 2.0 NBU</h1>
            <span style="font-size: 24px"
              >Все просто: авторизуйтесь в особистий <br />
              кабінет, щоб керувати звітами</span
            >
          </div>
          <div class="circle bottom"></div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { useDisplay } from 'vuetify'

export default {
  name: 'Auth',
  setup() {
    const { mobile } = useDisplay()
    return {
      mobile,
    }
  },
}
</script>

<style lang="scss" scoped>
.background {
  background: #ecf5fc;
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 50px;
  overflow: hidden;

  .circle {
    border: 25px solid var(--second-surface);
    border-radius: 50%;
    position: absolute;

    &.top {
      top: -100px;
      right: -80px;
      width: 290px;
      height: 290px;
    }

    &.bottom {
      width: 220px;
      height: 220px;
      bottom: -150px;
      left: 20px;
    }
  }
}
</style>

import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import { useAuth } from '@/store/auth/auth'
import { useUser } from '@/store/user'
import { storeToRefs } from 'pinia'

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  setTimeout(() => {
    document.title = `${to.meta?.title} | CR 2.0 NBU`
  })

  const { qrData, token } = storeToRefs(useAuth())
  const { isLoadedUser } = storeToRefs(useUser())

  const confirmLeavingGuard = () => {
    if (
      token.value &&
      from.meta.confirmLeavingGuard &&
      !to.matched.some(record => record.meta.confirmLeavingGuard)
    ) {
      const confirmLeaving = window.confirm(
        'Всі не збережені данні будуть видалені. Ви впевнені, що хочете покинути сторінку?'
      )
      if (confirmLeaving) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  }

  switch (true) {
    case to.matched[0].name !== 'auth' && !token.value:
      next({ name: 'login' })
      break
    case to.matched[0].name === 'auth' && isLoadedUser.value:
      next(false)
      break
    case to.matched[0].name === 'auth' && !!token.value:
      next({ name: 'main' })
      break
    case to.name === 'login' && qrData.value:
      next({ name: 'auth-confirm' })
      break
    case to.name === 'auth-confirm' && !qrData.value:
      next({ name: 'login' })
      break
    default:
      confirmLeavingGuard()
  }
})

export default router

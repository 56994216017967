<template>
  <div>
    <user-list v-if="$route.name === 'user-list'"></user-list>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import UserList from './UserList.vue'

export default {
  name: 'Index',
  components: { UserList },
}
</script>

import * as EUSignCP from 'euscp'
import { keyMediaTypes } from './keyMediaTypes.ts'

const { EndUserConstants, EndUser, EndUserKeyMedia } = EUSignCP

const PK_FORM_TYPE_FILE = 1
const PK_FORM_TYPE_KM = 2

const euSettings = {
  language: 'uk',
  encoding: 'utf-8',
  httpProxyServiceURL: '/eu/ProxyHandler.php',
  directAccess: true,
  CAs: '/eu/CAs.json',
  CACertificates: '/eu/CACertificates.p7b',
  allowedKeyMediaTypes: keyMediaTypes,
}

// Бібліотека для роботи з файловими ключами та серверами підпису, що не потребує
// встановлення додатково ПЗ
const euSignFile = new EndUser(
  '/eu/euscp.worker.ex-1.3.69.js',
  EndUserConstants.EndUserLibraryType.JS
)

const euSignKeyMedia = new EndUser(null, EndUserConstants.EndUserLibraryType.SW)

const euSign = euSignKeyMedia
let ctx
const formType = PK_FORM_TYPE_KM

// Ініціалізація бібліотеки
function initialize() {
  return new Promise(function (resolve, reject) {
    console.log('loading library...')
    let isInitialized = false

    if (euSign == euSignFile) {
      euSign
        .IsInitialized()
        .then(function (result) {
          isInitialized = result
          if (isInitialized) {
            console.log('EndUser: JS library already initialized')
            return
          }

          console.log('EndUser: JS library initializing...')
          return euSign.Initialize(euSettings)
        })
        .then(function () {
          if (isInitialized) return

          console.log('EndUser: JS library initialized')

          return
        })
        .then(function () {
          if (!isInitialized)
            console.log('EndUser: event listener for KSPs registered')

          isInitialized = true
          resolve()
        })
        .catch(function (e) {
          reject(e)
        })
    } else {
      // Перевірка чи встановлені необхідні модулі для роботи криптографічної бібліотеки
      euSign
        .GetLibraryInfo()
        .then(function (result) {
          console.log('library info', result)
          if (!result.supported) {
            throw 'Бібліотека web-підпису не підтримується в вашому браузері або ОС'
          }

          if (!result.loaded) {
            // Бібліотека встановлена, але потребує оновлення
            if (result.isNativeLibraryNeedUpdate) {
              throw (
                'Бібліотека web-підпису потребує оновлення. ' +
                'Будь ласка, встановіть оновлення за посиланням ' +
                result.nativeLibraryInstallURL
              )
            }

            // Якщо браузер підтримує web-розширення рекомендується
            // додатково до нативних модулів встановлювати web-розширення
            // Увага! Встановлення web-розширень ОБОВ'ЯЗКОВЕ для ОС Linux та ОС Windows Server
            if (
              result.isWebExtensionSupported &&
              !result.isWebExtensionInstalled
            ) {
              throw (
                'Бібліотека web-підпису потребує встановлення web-розширення. ' +
                'Будь ласка, встановіть web-розширення за посиланням ' +
                result.webExtensionInstallURL +
                ' та оновіть сторінку'
              )
            }

            // Бібліотека (нативні модулі) не встановлені
            throw (
              'Бібліотека web-підпису потребує встановлення. ' +
              'Будь ласка, встановіть бібліотеку за посиланням ' +
              result.nativeLibraryInstallURL +
              ' та оновіть сторінку'
            )
          }

          return euSign.IsInitialized()
        })
        .then(function (result) {
          isInitialized = result
          if (isInitialized) {
            console.log('EndUser: SW library already initialized')
            return
          }

          console.log('EndUser: SW library initializing...')
          return euSign.Initialize(euSettings)
        })
        .then(async function () {
          if (await euSign.IsInitialized()) {
            ctx = euSign.CtxCreate()
            console.log('lib context', ctx)
            console.log('EndUser: SW library initialized')
          } else {
            throw 'Помилка ініціалізації'
          }

          return euSign.GetKeyMedias()
        })
        .then(function (keyMedias) {
          console.log('keyMedias', keyMedias)

          resolve(keyMedias)
        })
        .catch(function (e) {
          reject(e)
        })
    }
  })
}

function readFile(file) {
  return new Promise(function (resolve) {
    const reader = new FileReader()
    reader.onloadend = function (evt) {
      if (evt.target.readyState != FileReader.DONE) return

      resolve({
        file: file,
        data: new Uint8Array(evt.target.result),
      })
    }
    reader.readAsArrayBuffer(file)
  })
}

function readPrivateKey(file, password) {
  console.log('file', file)
  console.log('password', typeof password === 'string')
  /*
		Загальне ім'я ЦСК з списку CAs.json, який видав сертифікат для ос. ключа.
		Якщо null бібліотека намагається визначити ЦСК автоматично за
		сервером CMP\сертифікатом. Встановлюється у випадках, коли ЦСК не
		підтримує CMP, та для пришвидшення пошуку сертифіката ос. ключа
	*/
  const caCN = null
  /*
		Сертифікати, що відповідають ос. ключу (масив об'єктів типу Uint8Array).
		Якщо null бібліотека намагається завантажити їх з ЦСК автоматично з сервера CMP.
		Встановлюється у випадках, коли ЦСК не підтримує CMP, та для пришвидшення
		пошуку сертифіката ос. ключа
	*/
  let pkCertificates = null

  return new Promise(function (resolve, reject) {
    if (!file) {
      reject('Не обрано файл з ос. ключем')

      return
    }

    if (!password) {
      reject('Не вказано пароль до ос. ключа')

      return
    }

    switch (formType) {
      case PK_FORM_TYPE_FILE:
        readFile(file)
          .then(function (result) {
            console.log('Private key file readed')

            // Якщо файл з ос. ключем має розширення JKS, ключ може містити декілька ключів,
            // для зчитування такого ос. ключа необхіно обрати який ключ повинен зчитуватися
            if (result.file.name.endsWith('.jks')) {
              return euSign
                .GetJKSPrivateKeys(result.data)
                .then(function (jksKeys) {
                  console.log('EndUser: jks keys got')

                  // Для спрощення прикладу обирається перший ключ
                  const pkIndex = 0

                  pkCertificates = []
                  for (let i = 0; i < jksKeys[pkIndex].certificates.length; i++)
                    pkCertificates.push(jksKeys[pkIndex].certificates[i].data)

                  return euSign.ReadPrivateKeyBinary(
                    jksKeys[pkIndex].privateKey,
                    password,
                    pkCertificates,
                    caCN
                  )
                })
            }

            return euSign.ReadPrivateKeyBinary(
              result.data,
              password,
              pkCertificates,
              caCN
            )
          })
          .then(function (result) {
            resolve(result)
          })
          .catch(function (e) {
            reject(e)
          })

        break

      case PK_FORM_TYPE_KM:
        if (!file) {
          reject('Не обрано носій з ос. ключем')
          return
        }

        var keyMedia = new EndUserKeyMedia(file)
        keyMedia.password = password
        euSign
          .CtxReadPrivateKey(ctx._result, keyMedia, pkCertificates, caCN)
          .then(function (result) {
            resolve(result)
          })
          .catch(function (e) {
            reject(e)
          })

        break
    }
  })
}

// async function signFile(options) {
//   if (euSign.IsPrivateKeyReaded()) {
//     const { file, asicType, signType, signLevel } = options
//     const fileBinary = await readFile(file)
//     const reference = new EndUserReference(file.name, fileBinary)
//     const signature = euSign.ASiCSignData(asicType, signType, signLevel, [
//       reference,
//     ]) // error - Операція не підтримується
//
//     return signature
//   }
// }

function resetPrivateKey() {
  euSign.ResetPrivateKey()
}

// const initialize = () => {
// 	return Promise.resolve()
// }

export { initialize, readPrivateKey, resetPrivateKey }

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 37 38"
    fill="none">
    <mask
      id="mask0_1284_6407"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="-1"
      y="0"
      width="38"
      height="38">
      <rect
        x="-0.000488281"
        y="0.982422"
        width="37"
        height="37"
        fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_1284_6407)">
      <path
        d="M16.3412 26.5943L27.21 15.7255L25.0516 13.5672L16.3412 22.2776L11.9475 17.8838L9.78913 20.0422L16.3412 26.5943ZM18.4995 34.9193C16.3669 34.9193 14.3627 34.5146 12.487 33.7052C10.6114 32.8958 8.97976 31.7974 7.59226 30.4099C6.20476 29.0224 5.10632 27.3908 4.29695 25.5151C3.48757 23.6394 3.08289 21.6352 3.08289 19.5026C3.08289 17.37 3.48757 15.3658 4.29695 13.4901C5.10632 11.6144 6.20476 9.98281 7.59226 8.59531C8.97976 7.20781 10.6114 6.10937 12.487 5.3C14.3627 4.49062 16.3669 4.08594 18.4995 4.08594C20.6322 4.08594 22.6364 4.49062 24.512 5.3C26.3877 6.10937 28.0193 7.20781 29.4068 8.59531C30.7943 9.98281 31.8928 11.6144 32.7021 13.4901C33.5115 15.3658 33.9162 17.37 33.9162 19.5026C33.9162 21.6352 33.5115 23.6394 32.7021 25.5151C31.8928 27.3908 30.7943 29.0224 29.4068 30.4099C28.0193 31.7974 26.3877 32.8958 24.512 33.7052C22.6364 34.5146 20.6322 34.9193 18.4995 34.9193Z"
        fill="#3BAD49" />
    </g>
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss"></style>

<template>
  <div>
    <ca-select
      v-if="keyType === 'file'"
      v-model="privateKeyData.caId"></ca-select>

    <file-key-reader
      v-if="keyType === 'file'"
      v-model="privateKeyData.privateKey"
      :readonly="isPrivateKeyReaded"
      :error-messages="getErrorMessages(v$.privateKey)"></file-key-reader>
    <media-key-reader
      v-if="keyType === 'media'"
      v-model="privateKeyData.privateKey"
      :readonly="isPrivateKeyReaded"
      :error-messages="getErrorMessages(v$.privateKey)"></media-key-reader>
    <b-input-password
      v-model="privateKeyData.password"
      label="Пароль"
      :readonly="isPrivateKeyReaded"
      :error-messages="getErrorMessages(v$.password)"
      @blur="v$.password.$touch()"></b-input-password>

    <v-divider class="mt-1 mb-1"></v-divider>

    <key-info v-if="keyInfo" :keyInfo="keyInfo"></key-info>
    <read-key-status
      v-if="keyInfo"
      :is-readed="isPrivateKeyReaded"></read-key-status>

    <div class="d-flex align-center gap-4 mt-4">
      <v-btn variant="outlined" color="black" @click="$emit('cancel')">
        Назад
      </v-btn>
      <v-btn v-if="keyInfo" color="black" @click="extract">Вилучити</v-btn>
      <v-btn
        v-if="!isPrivateKeyReaded"
        color="amber-darken-1"
        class="text-white"
        :loading="$loading.isLoading('readKey')"
        @click="readKey">
        Зчитати підпис
      </v-btn>
      <v-btn
        v-if="isPrivateKeyReaded"
        color="amber-darken-1"
        class="text-white"
        :loading="$loading.isLoading('signFile')"
        @click="signFile">
        Підписати і надіслати документи
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, reactive, ref, inject } from 'vue'
import CaSelect from './CaSelect.vue'
import ReadKeyStatus from './ReadKeyStatus.vue'
import FileKeyReader from './FileKeyReader.vue'
import MediaKeyReader from './MediaKeyReader.vue'
import KeyInfo from './KeyInfo.vue'
import { BInputPassword } from 'best-modules/components'
import { KeyType } from '../types'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import {
  readSignature as readSignatureRequest,
  createSession,
  getPackageStatus,
  signDocument as signDocumentRequest,
} from '@/request/signature'
import { useEncryptPrivateKey } from './encryptPriteKey'
import { SignDocumentDto } from '@/utils/types'
import { dialogKey } from '../injectionKeys'
import { getErrorMessages } from 'best-modules/utils'
import { readPrivateKey } from '@/utils/signature/signature'

export default {
  name: 'SignLocal',
  emits: ['cancel'],
  components: {
    CaSelect,
    FileKeyReader,
    KeyInfo,
    ReadKeyStatus,
    MediaKeyReader,
    BInputPassword,
  },
  props: {
    keyType: {
      type: String as PropType<Omit<KeyType, 'cloud'>>,
    },
  },
  methods: { getErrorMessages },
  setup(props) {
    const dialog = inject(dialogKey)
    const isPrivateKeyReaded = ref<boolean>()
    const keyInfo = ref()
    const privateKeyData = reactive({
      password: null,
      privateKey: null,
      caId: null,
    })
    let sessionId: string
    const encryptedData = ref()

    const rules = {
      privateKey: { required },
      password: { required },
    }
    const v$ = useVuelidate(rules, privateKeyData)

    const { encryptPrivateKey } = useEncryptPrivateKey()

    const extract = () => {
      privateKeyData.privateKey = null
      privateKeyData.password = null
      keyInfo.value = null
      isPrivateKeyReaded.value = false
    }

    const readKey = async () => {
      await v$.value.$validate()
      if (!v$.value.$invalid) {
        if (props.keyType === 'file') {
          const signature = privateKeyData.privateKey[0].split(';base64,')[1]
          encryptedData.value = await encryptPrivateKey(
            signature,
            privateKeyData.password
          )
          return readSignatureRequest(encryptedData.value, privateKeyData.caId)
            .then(res => {
              keyInfo.value = res.certificateInfo
              sessionId = res.sessionId
              isPrivateKeyReaded.value = true
            })
            .catch(() => {
              isPrivateKeyReaded.value = false
            })
        }
        if (props.keyType === 'media') {
          const privateKeyCtx = await readPrivateKey(
            privateKeyData.privateKey,
            privateKeyData.password
          )
          const signature = JSON.stringify(privateKeyCtx)
          encryptedData.value = await encryptPrivateKey(
            signature,
            privateKeyData.password
          )
          return createSession(encryptedData.value)
            .then(res => {
              // keyInfo.value = res.certificateInfo
              sessionId = res.sessionId
              isPrivateKeyReaded.value = true
            })
            .catch(() => {
              isPrivateKeyReaded.value = false
            })
        }
      }
    }

    const signFile = () => {
      const sendRequest = dialog.dialogData?.isGetPackageStatus
        ? getPackageStatus
        : signDocumentRequest
      return sendRequest({
        ...encryptedData.value,
        certificateInfo: keyInfo.value,
        sessionId,
        packages: dialog.dialogData.packages,
      } as SignDocumentDto).then(res => {
        dialog.submit(res)

        return res
      })
    }

    return {
      extract,
      keyInfo,
      privateKeyData,
      readKey,
      signFile,
      v$,
      isPrivateKeyReaded,
    }
  },
}
</script>

<style scoped lang="scss"></style>

<template>
  <v-row>
    <v-col cols="12" md="6" sm="12">
      <div v-for="(item, idx) in content" :key="idx">
        <h3 v-if="item.type === 'title'" class="mb-4">{{ item.data }}</h3>
        <p v-if="item.type === 'paragraph'" class="mb-4 mt-4">
          {{ item.data }}
        </p>
        <div v-if="item.type === 'list'" class="mt-4 mb-4">
          <div
            v-for="(itm, i) in item.data"
            :key="i"
            class="d-flex align-center gap-1">
            <div>
              <CheckCircle />
            </div>
            <span class="d-block">{{ itm }}</span>
          </div>
        </div>
        <div v-if="item.type === 'tip'" class="validation-block">
          <div>
            <span class="text-black">Підказка:</span>
            <div v-for="(itm, i) in item.data" :key="i">
              <span v-if="itm.type === 'text'">{{ itm.data }}</span>
              <a
                v-if="itm.type === 'link'"
                class="link d-inline"
                :href="itm.href"
                target="_blank">
                {{ itm.data }}
              </a>
            </div>
          </div>
        </div>
        <v-btn v-if="item.type === 'btn'">
          {{ item.data }}
        </v-btn>
      </div>
    </v-col>
    <v-col cols="12" md="6" sm="12">
      <div class="d-flex justify-end">
        <IntegrationBest v-if="image === 'best'" />
        <IntegrationOneC v-if="image === 'oneC'" />
        <IntegrationApi v-if="image === 'api'" />
      </div>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import CheckCircle from '@/assets/images/CheckCircle.vue'
import IntegrationBest from '@/assets/images/IntegrationBest.vue'
import IntegrationOneC from '@/assets/images/IntegrationOneC.vue'
import IntegrationApi from '@/assets/images/IntegrationApi.vue'

export default defineComponent({
  components: { IntegrationApi, IntegrationOneC, IntegrationBest, CheckCircle },
  props: {
    content: {
      type: Object as PropType<{
        type: 'title' | 'paragraph' | 'list' | 'tip'
        data: string | string[]
      }>,
      required: true,
    },
    image: {
      type: String,
    },
  },
})
</script>

<style scoped lang="scss">
.validation-block {
  background: var(--light-grey);
  color: var(--grey);
  padding: 12px;
  border-radius: 4px;
  border-left: 2px solid #ffb200;
}
</style>

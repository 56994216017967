<template>
  <div>
    <b-input
      v-model="newValue"
      placeholder="введіть значення"
      @keyup.enter="pushValue()">
      <template #append>
        <v-btn @click="pushValue()"> Додати </v-btn>
      </template>
    </b-input>
    <div v-for="(v, idx) in value" :key="v">
      <div class="d-flex align-center justify-space-between">
        <span>{{ v }}</span>
        <div>
          <v-btn
            icon="mdi-delete"
            density="compact"
            variant="plain"
            color="error"
            @click="removeValue(v)"></v-btn>
        </div>
      </div>
      <v-divider v-if="idx < value.length - 1" class="mt-2 mb-2"></v-divider>
    </div>
    <v-scroll-y-transition hide-on-leave>
      <div v-show="getErrorMessages(v$.value).length" class="text-error">
        {{ getErrorMessages(v$.value)[0] }}
      </div>
    </v-scroll-y-transition>
  </div>
</template>

<script lang="ts">
import { PropType, ref } from 'vue'
import { Dialog } from 'best-modules/plugins'
import { PackageTableCol } from '@/pages/report/package/package-table/types'
import { useVuelidate } from '@vuelidate/core'
import { minLength, required } from '@vuelidate/validators'
import { getErrorMessages, keepArray } from 'best-modules/utils'

export default {
  name: 'PackageSelectMultiple',
  props: {
    dialog: {
      type: Object as PropType<Dialog<'PackageSelectMultiple'>>,
    },
  },
  methods: { getErrorMessages },
  setup(props) {
    const newValue = ref<string>()
    const value = ref<string[]>(keepArray(props.dialog.dialogData.value))
    const col: PackageTableCol = props.dialog.dialogData.col

    const rules = {
      value: { required, minLength: minLength(1) },
    }
    const v$ = useVuelidate(rules, { value })

    const pushValue = (): void => {
      if (newValue.value && !value.value.includes(newValue.value)) {
        value.value.push(newValue.value)
        newValue.value = null
      }
    }
    const removeValue = (val: string): void => {
      value.value = value.value.filter(v => v !== val)
    }

    const submit = () => {
      return Promise.resolve([...value.value])
    }

    return {
      submit,
      v$,
      col,
      value,
      newValue,
      pushValue,
      removeValue,
    }
  },
}
</script>

<style scoped lang="scss"></style>

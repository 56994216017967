<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 37 37"
    fill="none">
    <mask
      id="mask0_1286_6478"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="-1"
      y="-1"
      width="38"
      height="38">
      <rect
        x="-0.000305176"
        y="-0.00390625"
        width="37"
        height="37"
        fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_1286_6478)">
      <path
        d="M14.7995 24.0532L16.9578 21.8178L13.6432 18.5033L16.9578 15.1887L14.7995 12.9533L9.24945 18.5033L14.7995 24.0532ZM22.1995 24.0532L27.7495 18.5033L22.1995 12.9533L20.0411 15.1887L23.3557 18.5033L20.0411 21.8178L22.1995 24.0532ZM7.70779 32.3782C6.85987 32.3782 6.134 32.0763 5.53018 31.4725C4.92636 30.8687 4.62445 30.1428 4.62445 29.2949V7.71159C4.62445 6.86367 4.92636 6.1378 5.53018 5.53398C6.134 4.93016 6.85987 4.62825 7.70779 4.62825H14.1828C14.5168 3.70325 15.0757 2.95812 15.8594 2.39284C16.643 1.82756 17.5231 1.54492 18.4995 1.54492C19.4758 1.54492 20.3559 1.82756 21.1396 2.39284C21.9232 2.95812 22.4821 3.70325 22.8161 4.62825H29.2911C30.139 4.62825 30.8649 4.93016 31.4687 5.53398C32.0726 6.1378 32.3745 6.86367 32.3745 7.71159V29.2949C32.3745 30.1428 32.0726 30.8687 31.4687 31.4725C30.8649 32.0763 30.139 32.3782 29.2911 32.3782H7.70779ZM18.4995 6.55534C18.8335 6.55534 19.1097 6.44614 19.3281 6.22773C19.5465 6.00933 19.6557 5.73311 19.6557 5.39909C19.6557 5.06506 19.5465 4.78884 19.3281 4.57044C19.1097 4.35204 18.8335 4.24284 18.4995 4.24284C18.1654 4.24284 17.8892 4.35204 17.6708 4.57044C17.4524 4.78884 17.3432 5.06506 17.3432 5.39909C17.3432 5.73311 17.4524 6.00933 17.6708 6.22773C17.8892 6.44614 18.1654 6.55534 18.4995 6.55534Z"
        fill="#FC563A" />
    </g>
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss"></style>

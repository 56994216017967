<template>
  <v-row>
    <v-col cols="12" md="6">
      <AddressItem
        :address="company.addresses.find(a => a.typeId === 1)"
        :type-id="1"
        :companyId="company.id"
        title="Адреса реєстрації" />
    </v-col>
    <v-col cols="12" md="6">
      <AddressItem
        :address="company.addresses.find(a => a.typeId === 2)"
        :type-id="2"
        :companyId="company.id"
        title="Фактична адреса" />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import AddressItem from '@/pages/companies/single/components/AddressItem.vue'
import { PropType } from 'vue'
import { CachedObjectProxy } from 'best-modules/utils/cachedObject'
import { Company } from '@/utils/types'

export default {
  name: 'Contacts',
  components: { AddressItem },
  props: {
    company: {
      type: Object as PropType<CachedObjectProxy<Company>>,
      required: true,
    },
  },
}
</script>

<style scoped lang="scss"></style>

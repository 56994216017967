<template>
  <div>
    <v-data-table
      v-model="selectedPersons"
      :items="items"
      :headers="headers"
      show-select
      :select-strategy="dialog.dialogData.col.multiple ? 'all' : 'single'"
      item-value="id">
      <template #bottom></template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { computed, PropType, ref } from 'vue'
import { Dialog } from 'best-modules/plugins'
import { getPackagePersonName } from '@/pages/report/package/package-table/helpers'
import { DataTableHeader } from 'best-modules/components'
import { keepArray } from 'best-modules/utils'
import { getUserCompanyPersonShort } from '@/request/report'
import { PersonInfo, PersonShort } from '@/utils/types'

export default {
  name: 'PackageSelectPersonInfo',
  props: {
    dialog: {
      type: Object as PropType<Dialog<'PackageSelectPersonInfo'>>,
    },
  },
  methods: { getPackagePersonName },
  async setup(props) {
    let userCompany: PersonShort
    await getUserCompanyPersonShort().then(res => {
      userCompany = res
    })

    const isUserCompanyAdded = props.dialog.dialogData.persons.some(p => {
      return p.person_id_short === userCompany.person_id_short
    })

    const getDefaultPersons = (): string[] => {
      return keepArray(props.dialog.dialogData.value).map(p => {
        if (p.person_id_full) {
          return `person_id_full:${p.person_id_full}`
        }
        if (p.person_id_short) {
          return `person_id_short:${p.person_id_short}`
        }
        console.warn(
          'PackageSelectPersonInfo.vue - getDefaultPersons() - invalid value'
        )
        return null
      })
    }

    const selectedPersons = ref<string[]>(getDefaultPersons())

    const submit = () => {
      const res: PersonInfo[] = selectedPersons.value
        .filter(person => {
          return items.value.map(i => i.id).includes(person)
        })
        .map(person => {
          const [key, value] = person.split(':')
          const p = {} as PersonInfo
          p[key] = value
          return p
        })

      let personShort: PersonShort | null = null

      if (
        !isUserCompanyAdded &&
        res.some(p => p.person_id_short === userCompany.person_id_short)
      ) {
        personShort = userCompany
      }

      if (props.dialog.dialogData.col.multiple) {
        return Promise.resolve({ personInfo: res, personShort })
      } else {
        return Promise.resolve({ personInfo: res[0], personShort })
      }
    }

    const items = computed<
      Array<{ id: string; visibleId: string; name: string }>
    >(() => {
      const persons = props.dialog.dialogData.persons || []
      if (!isUserCompanyAdded) {
        persons.push(userCompany)
      }
      return persons.map(p => {
        let id: string
        if (p.person_id_full) {
          id = `person_id_full:${p.person_id_full}`
        }
        if (p.person_id_short) {
          id = `person_id_short:${p.person_id_short}`
        }
        return {
          id,
          visibleId: p.person_id_full || p.person_id_short,
          name: getPackagePersonName(p),
        }
      })
    })
    const headers: DataTableHeader[] = [
      {
        title: 'ID',
        key: 'visibleId',
      },
      {
        title: 'ПІБ/Назва',
        key: 'name',
      },
    ]

    return { selectedPersons, headers, submit, items }
  },
}
</script>

<style scoped lang="scss"></style>

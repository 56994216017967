<template>
  <div>
    <b-input
      v-model="token.name"
      label="Назва"
      :error-messages="getErrorMessages(v$.name)"
      @blur="v$.name.$touch()"></b-input>
    <b-input-date
      v-model="token.expires_at"
      model-value-format="iso"
      label="Дата закінчення"
      :error-messages="getErrorMessages(v$.expires_at)"
      @blur="v$.expires_at.$touch()"></b-input-date>
  </div>
</template>

<script lang="ts">
import { PropType, reactive } from 'vue'
import { Dialog } from 'best-modules/plugins'
import { createCompanyToken, updateCompanyToken } from '@/request/company'
import { CompanyTokenCredentials } from '@/utils/types'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { getErrorMessages } from 'best-modules/utils'
import { BInputDate } from 'best-modules/components'
import { openDialog } from '@/plugins/dialog'

export default {
  name: 'CompanyToken',
  components: { BInputDate },
  props: {
    dialog: {
      type: Object as PropType<Dialog<'CompanyToken'>>,
    },
  },
  methods: {
    getErrorMessages,
  },
  setup(props) {
    const token: CompanyTokenCredentials = reactive({
      name: 'IntegrationToken',
      expires_at: null,
      companyId: props.dialog.dialogData.companyId,
    })
    let tokenId: number

    if (props.dialog.action === 'update') {
      token.name = props.dialog.dialogData.token.name
      token.expires_at = props.dialog.dialogData.token.expires_at.substring(
        0,
        10
      )
      tokenId = props.dialog.dialogData.token.id
    }

    const rules = {
      name: { required },
      expires_at: { required },
    }

    const v$ = useVuelidate(rules, token)

    const submit = () => {
      if (props.dialog.action === 'create') {
        return createCompanyToken(token).then(res => {
          openDialog({
            name: 'CompanyTokenPlainText',
            dialogData: {
              plainTextToken: res.plainTextToken,
            },
          })
          return res.token
        })
      }
      if (props.dialog.action === 'update') {
        return updateCompanyToken(token, tokenId)
      }
    }

    return { submit, token, v$ }
  },
}
</script>

<style scoped lang="scss"></style>

<template>
  <div>
    <b-select
      v-model="tariff.tariffId"
      :items="$directory.get('tariffTypes')"
      :error-messages="getErrorMessages(v$.tariffId)"
      label="Тариф"
      :loading="$loading.isLoading('tariffTypes')"
      @focus="$directory.fill('tariffTypes')"
      @blur="v$.tariffId.$touch()"></b-select>
    <v-row>
      <v-col cols="6">
        <b-select
          v-model="tariff.period.year"
          label="Рік"
          placeholder="оберіть рік"
          :items="[new Date().getFullYear(), new Date().getFullYear() + 1]"
          item-value="year"
          item-title="year"
          :readonly="dialog.action === 'update'"
          :error-messages="getErrorMessages(v$.period.year)"
          @blur="v$.period.year.$touch()"></b-select>
      </v-col>
      <v-col cols="6">
        <b-select
          v-model="tariff.period.month"
          label="Місяць"
          placeholder="оберіть місяць"
          :readonly="dialog.action === 'update'"
          :items="$directory.get('months').filter(m => !Array.isArray(m.id))"
          :error-messages="getErrorMessages(v$.period.month)"
          @blur="v$.period.month.$touch()"></b-select>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { PropType, reactive } from 'vue'
import { Dialog } from 'best-modules/plugins'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { createCompanyTariff, updateCompanyTariff } from '@/request/company'
import { fillDirectory } from '@/directory'
import { getErrorMessages } from 'best-modules/utils'
import { Period } from '@/utils/helpers'
import { Company } from '@/utils/types'
import { useUser } from '@/store/user'

export default {
  name: 'CompanyTariff',
  props: {
    dialog: {
      type: Object as PropType<Dialog<'CompanyTariff'>>,
    },
  },
  methods: { getErrorMessages },
  setup(props) {
    const { userData } = useUser()
    const {
      companyTariffId,
      companyId,
      tariffId = null,
      period = null,
    } = props.dialog.dialogData as {
      companyTariffId: string
      companyId: string
      tariffId: number
      period: string
    }
    const tariff = reactive<{
      tariffId: number
      period: Period
    }>({ tariffId, period: new Period(period) })

    const rules = {
      tariffId: { required: required },
      period: {
        required: required,
        year: { required: required },
        month: { required: required },
      },
    }
    const v$ = useVuelidate(rules, tariff)

    const submit = async () => {
      let updatedCompany: Company
      if (props.dialog.action === 'create') {
        const requestObject = {
          tariffId: tariff.tariffId,
          period: tariff.period.toString(),
          companyId,
        }
        updatedCompany = await createCompanyTariff(requestObject)
      }
      if (props.dialog.action === 'update') {
        updatedCompany = await updateCompanyTariff(
          companyTariffId,
          tariff.tariffId
        )
      }
      const isCurrentMonth = tariff.period.month === new Date().getMonth() + 1
      const isCurrentCompany =
        companyId === userData.watch_company_user_role.companyId

      if (isCurrentMonth && isCurrentCompany) {
        userData.watch_company_user_role.company.current_company_tariff =
          updatedCompany.company_tariffs.find(t => {
            const p = new Period(t.period)
            return (
              p.year === tariff.period.year && p.month === tariff.period.month
            )
          })
      }

      return updatedCompany
    }

    fillDirectory('tariffTypes')

    return { tariff, v$, submit }
  },
}
</script>

<style scoped lang="scss"></style>

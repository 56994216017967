import { ISODate } from 'best-modules/utils'
import { PackageTableValueF170, PackageTableValueSimple } from './types'
import { PersonShort, PersonFull } from '@/utils/types'

const isValueF170 = (value: any): value is PackageTableValueF170 => {
  return (
    value !== null &&
    typeof value === 'object' &&
    !Array.isArray(value) &&
    ('value' in value || 'f170' in value)
  )
}

const isValueDate = (
  value: any,
  format: 'iso' | 'locale' = 'iso'
): value is ISODate => {
  const isoRegex: RegExp = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/
  const localeRegex: RegExp = /^(0[1-9]|[12]\d|3[01]).(0[1-9]|1[0-2]).\d{4}$/

  let regex: RegExp
  if (format === 'iso') {
    regex = isoRegex
  }
  if (format === 'locale') {
    regex = localeRegex
  }
  if (!regex) {
    throw new Error('isValueDate() - invalid format')
  }

  return typeof value === 'string' && value.length === 10 && regex.test(value)
}

const isEmpty = (
  v: any
): v is Exclude<PackageTableValueSimple, null | undefined | '' | []> => {
  if (
    typeof v === 'boolean' ||
    typeof v === 'number' ||
    (typeof v === 'string' && !!v.length) ||
    (Array.isArray(v) && !!v.length) ||
    (!Array.isArray(v) && typeof v === 'object' && v !== null)
  ) {
    return false
  } else {
    return true
  }
}

function getPackagePersonName(person: PersonShort | PersonFull): string {
  if (!person) {
    return null // 'Не вказано'
  }
  const defaultName = `ID: ${person.person_id_short || person.person_id_full}`
  const p =
    person.ind_person ||
    person.non_res_ind_person ||
    person.entity ||
    person.non_res_entity ||
    person.ind_person_short ||
    person.entity_short
  if (!p) {
    return defaultName
  }
  if (['1', '7'].includes(person.f082_person_type)) {
    const name = p.first_name
    const lastName = p.last_name?.value
    const patronymic = p.patronymic?.value
    const fullName = [lastName, name, patronymic].filter(Boolean).join(' ')
    return fullName || defaultName
  } else {
    return p.short_name_entity?.value || p.short_name_entity || defaultName
  }
}

export { isEmpty, getPackagePersonName, isValueF170, isValueDate }

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 37 38"
    fill="none">
    <mask
      id="mask0_1284_6375"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="37"
      height="38">
      <rect y="0.96875" width="37" height="37" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_1284_6375)">
      <path
        d="M9.25025 18.7018V10.2227H17.7294V18.7018H9.25025ZM9.25025 28.7227V20.2435H17.7294V28.7227H9.25025ZM19.2711 18.7018V10.2227H27.7503V18.7018H19.2711ZM19.2711 28.7227V20.2435H27.7503V28.7227H19.2711ZM7.70858 33.3477C6.86066 33.3477 6.13479 33.0458 5.53097 32.4419C4.92715 31.8381 4.62524 31.1123 4.62524 30.2643V8.68099C4.62524 7.83307 4.92715 7.10721 5.53097 6.50339C6.13479 5.89957 6.86066 5.59766 7.70858 5.59766H29.2919C30.1398 5.59766 30.8657 5.89957 31.4695 6.50339C32.0733 7.10721 32.3753 7.83307 32.3753 8.68099V30.2643C32.3753 31.1123 32.0733 31.8381 31.4695 32.4419C30.8657 33.0458 30.1398 33.3477 29.2919 33.3477H7.70858ZM7.70858 30.2643H29.2919V8.68099H7.70858V30.2643Z"
        fill="#5B93FF" />
    </g>
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss"></style>

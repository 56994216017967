<template>
  <div>
    <div class="d-flex justify-space-between mb-3">
      <div>
        <v-app-bar-title class="font-weight-bold mb-2">{{
          $route?.meta?.title
        }}</v-app-bar-title>
      </div>
      <v-btn
        v-if="userAccessRights.includes('user.create')"
        class="mb-3"
        @click="createUser">
        <span>Створити користувача</span>
        <v-icon class="ml-2">mdi-plus</v-icon>
      </v-btn>
    </div>
    <b-server-table
      :request-func="getUserList"
      :show-search="true"
      :headers="userListHeaders"
      @click:row="
        $event => {
          if (userAccessRights.includes('user.single')) {
            $router.push({ name: 'user-single', params: { id: $event.id } })
          }
        }
      ">
      <template #[`item.name`]="{ item }">
        {{ getPersonName(item) }}
      </template>
    </b-server-table>
  </div>
</template>

<script lang="ts">
import { BServerTable } from 'best-modules/components'
import { getUserList } from '@/request/user'
import { userListHeaders } from '@/utils/tableHeaders'
import { getPersonName } from 'best-modules/utils'
import { openDialog } from '@/plugins/dialog'
import { useRouter } from 'vue-router'
import { useUser } from '@/store/user'
import { storeToRefs } from 'pinia'

export default {
  name: 'UserList',
  components: { BServerTable },
  data: () => {
    return { userListHeaders }
  },
  methods: { getPersonName, openDialog },
  setup() {
    const router = useRouter()
    const { userAccessRights } = storeToRefs(useUser())

    const createUser = () => {
      openDialog({
        name: 'User',
        params: {
          cb: u => {
            router.push({ name: 'user-single', params: { id: u.id } })
          },
        },
      })
    }

    return { getUserList, createUser, userAccessRights }
  },
}
</script>

const CYRILLIC: RegExp = /[а-яА-ЯєіїЄІЇ]/
const UP_CYRILLIC: RegExp = /[А-ЯЄІЇ]/
const LOW_CYRILLIC: RegExp = /[а-яєії]/
const EN: RegExp = /[a-zA-Z]/
const UP_EN: RegExp = /[A-Z]/
const LOW_EN: RegExp = /[a-z]/
const NUMBER: RegExp = /[0-9]/
const SPEC: RegExp = /["’'”/,().–-]/
const NOT_CYRILLIC: RegExp = /[^а-яА-ЯєіїЄІЇ]/
const NOT_EN: RegExp = /[^a-zA-Z]/
const NOT_NUMBER: RegExp = /[^0-9]/
const NOT_SPEC: RegExp = /["’'”/,().–-]/
const SPACE: RegExp = /\s/
const NOT_SPACE: RegExp = /^\s/

const serializeRegex = (reg: RegExp | string): string => {
  if (typeof reg === 'string') {
    return reg
  }
  if (reg instanceof RegExp) {
    if (reg.source.startsWith('[') && reg.source.endsWith(']')) {
      return reg.source.substring(1, reg.source.length - 1)
    } else {
      return reg.source
    }
  }
}
const deserializeRegex = (reg: string): RegExp => {
  return new RegExp(`[${reg}]`)
}
const concatRegex = (...reg: Array<RegExp | string>): RegExp => {
  const serialized = reg.map(serializeRegex)
  const hyphenationIdx = serialized.indexOf('-')
  if (hyphenationIdx >= 0) {
    serialized.splice(hyphenationIdx, 1)
    serialized.push('-')
  }
  return deserializeRegex(serialized.join(''))
}

export {
  SPACE,
  CYRILLIC,
  UP_CYRILLIC,
  LOW_CYRILLIC,
  EN,
  UP_EN,
  LOW_EN,
  NUMBER,
  SPEC,
  NOT_CYRILLIC,
  NOT_EN,
  NOT_NUMBER,
  NOT_SPEC,
  NOT_SPACE,
  concatRegex,
}

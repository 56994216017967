<template>
  <div>
    <v-tabs
      v-model="currentTab"
      class="mb-3"
      stacked
      height="auto"
      @update:model-value="
        $router.replace({
          name: $route.name,
          query: { section: $event as string },
        })
      ">
      <v-tab
        v-for="(item, idx) in tabItems"
        :key="item.component"
        :value="item.component">
        <div class="tab mb-2" :class="{ 'tab--error': item.error }">
          <div class="d-flex justify-center mb-2">
            <div
              class="tab-num"
              :class="{ 'tab-num--active': currentTab === item.component }">
              {{ idx + 1 }}
            </div>
          </div>
          <div>{{ item.title }}</div>
        </div>
      </v-tab>
    </v-tabs>
    <v-window v-model="currentTab">
      <v-window-item
        v-for="item in tabItems"
        :key="item.component"
        :value="item.component">
        <suspense>
          <component :is="item.component"></component>

          <template #fallback>
            <b-section-loader></b-section-loader>
          </template>
        </suspense>
      </v-window-item>
    </v-window>
  </div>
</template>

<script lang="ts">
import { BSectionLoader } from 'best-modules/components'
import { computed, inject, ref } from 'vue'
import {
  packageDataKey,
  validationErrorsKey,
} from '@/pages/report/package/injectionKeys'
import { defineAsyncComponent } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default {
  components: {
    Collateral: defineAsyncComponent(
      () =>
        import(
          '@/pages/report/package/document/components/collateral/CollateralIndex.vue'
        )
    ),
    Loan: defineAsyncComponent(
      () =>
        import('@/pages/report/package/document/components/loan/LoanIndex.vue')
    ),
    Liability: defineAsyncComponent(
      () =>
        import(
          '@/pages/report/package/document/components/liability/LiabilityIndex.vue'
        )
    ),
    Contract: defineAsyncComponent(
      () => import('@/pages/report/package/document/components/Contract.vue')
    ),
    ClientProfile: defineAsyncComponent(
      () =>
        import(
          '@/pages/report/package/document/components/client-profile/ClientProfile.vue'
        )
    ),
    GeneralInfo: defineAsyncComponent(
      () =>
        import(
          '@/pages/report/package/document/components/general-info/GeneralInfo.vue'
        )
    ),
    PersonShort: defineAsyncComponent(
      () =>
        import(
          '@/pages/report/package/document/components/related-persons/PersonShort.vue'
        )
    ),
    RelatedPersons: defineAsyncComponent(
      () =>
        import(
          '@/pages/report/package/document/components/related-persons/RelatedPersons.vue'
        )
    ),
    BSectionLoader,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    if (!route.query.section) {
      router.replace({ name: route.name, query: { section: 'ClientProfile' } })
    }
    const packageData = inject(packageDataKey)
    const validationErrors = inject(validationErrorsKey)

    const errors = computed(() => {
      return Object.keys(validationErrors)
    })

    const relatedPersonShortIds = computed(() => {
      return (
        packageData.value.person_full[0].related_person?.map(p => {
          return p.person_info.person_id_short
        }) || []
      )
    })
    const otherPersonShortIds = computed(() => {
      return (
        packageData.value.person_short
          ?.map(p => p.person_id_short)
          .filter(id => !relatedPersonShortIds.value.includes(id)) || []
      )
    })

    const isPersonShortValid = (personIdShort: string) => {
      const personShortIdx = packageData.value.person_short.findIndex(
        p => p.person_id_short === personIdShort
      )
      return errors.value.reduce((a, e) => {
        return a || e.includes(`person_short.${personShortIdx}`)
      }, false)
    }

    const currentTab = ref(route.query.section)
    const tabItems = computed(() => {
      return [
        {
          title: 'Профіль клієнта',
          component: 'ClientProfile',
          error: errors.value.reduce((a, e) => {
            return (
              a ||
              (e.startsWith('person_full') &&
                !e.includes('ind_person') &&
                !e.includes('entity') &&
                !e.includes('non_res_ind_person') &&
                !e.includes('non_res_entity') &&
                !e.includes('related_person'))
            )
          }, false),
        },
        {
          title: 'Загальні дані',
          component: 'GeneralInfo',
          error: errors.value.reduce((a, e) => {
            return (
              a ||
              (e.startsWith('person_full') &&
                (e.includes('ind_person') || e.includes('entity')))
            )
          }, false),
        },
        {
          title: "Пов'язані особи з боржником",
          component: 'RelatedPersons',
          error:
            errors.value.reduce((a, e) => {
              return a || e.includes('related_person')
            }, false) ||
            relatedPersonShortIds.value.reduce((a, id) => {
              return a || isPersonShortValid(id)
            }, false),
        },
        {
          title: "Інші пов'язані особи",
          component: 'PersonShort',
          error: otherPersonShortIds.value.reduce((a, id) => {
            return a || isPersonShortValid(id)
          }, false),
        },
        {
          title: "Фінансові зобов'язання",
          component: 'Liability',
          error: errors.value.reduce(
            (a, e) => a || e.startsWith('liability'),
            false
          ),
        },
        {
          title: 'Активні операції',
          component: 'Loan',
          error: errors.value.reduce(
            (a, e) => a || e.startsWith('loan'),
            false
          ),
        },
        {
          title: 'Забезпечення',
          component: 'Collateral',
          error: errors.value.reduce(
            (a, e) => a || e.startsWith('collateral'),
            false
          ),
        },
        {
          title: 'Узагальнююча угода',
          component: 'Contract',
          error: errors.value.reduce(
            (a, e) => a || e.startsWith('contract'),
            false
          ),
        },
      ]
    })

    return { currentTab, tabItems, packageData }
  },
}
</script>

<style lang="scss" scoped>
.tab {
  &--error {
    color: rgb(var(--v-theme-error)) !important;
    & .tab-num {
      border-color: rgb(var(--v-theme-error)) !important;
      &--active {
        background: rgb(var(--v-theme-error)) !important;
      }
    }
  }
  &-num {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid rgb(var(--v-theme-primary));
    &--active {
      color: #fff;
      background: rgb(var(--v-theme-primary));
      border: none;
    }
  }
}
</style>

<template>
  <div>
    <v-row>
      <v-col cols="6">
        <b-select
          v-model="report.year"
          label="Рік"
          placeholder="оберіть рік"
          :items="$directory.get('yearsFrom2024')"
          item-value="year"
          item-title="year"
          :error-messages="getErrorMessages(v$.year)"
          @blur="v$.year.$touch()"></b-select>
      </v-col>
      <v-col cols="6">
        <b-select
          v-model="report.month"
          label="Місяць"
          placeholder="оберіть місяць"
          :items="$directory.get('months').filter(m => !Array.isArray(m.id))"
          :error-messages="getErrorMessages(v$.month)"
          @blur="v$.month.$touch()"></b-select>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { getErrorMessages } from 'best-modules/utils'
import { createReport } from '@/request/report'
import { reactive } from 'vue'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

export default {
  name: 'Report',
  methods: { getErrorMessages },
  setup() {
    const report = reactive({
      year: null,
      month: null,
    })
    const rules = { year: { required }, month: { required } }

    const v$ = useVuelidate(rules, report)
    const submit = () => {
      return createReport(report)
    }

    return { report, v$, submit }
  },
}
</script>

<style scoped></style>

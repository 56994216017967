<template>
  <div>
    <v-toolbar color="transparent" class="pr-2 pl-2 pt-2" :height="45">
      <v-toolbar-title>
        <h4>{{ title }}</h4>
      </v-toolbar-title>
      <v-btn
        class="ma-0"
        icon
        variant="plain"
        density="comfortable"
        @click="closeDialog">
        <v-icon color="var(--grey)">mdi-close-circle-outline</v-icon>
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script lang="ts">
export default {
  name: 'DialogToolbar',
  props: {
    title: { type: String },
    subtitle: { type: String },
    closeDialog: { type: Function },
  },
}
</script>

<style scoped lang="scss"></style>

<template>
  <div class="d-flex justify-end mt-5 flex-column align-end">
    <v-pagination
      v-model="page"
      :length="length"
      size="small"
      total-visible="4"
      rounded="4"
      variant="outlined"
      density="comfortable">
      <template #prev="props">
        <v-btn
          :style="{
            background: props.disabled ? '#7E92A2' : '#fff',
            border: !props.disabled ? '1px solid #dcdfe2' : null,
          }"
          :variant="props.disabled ? 'tonal' : 'default'"
          size="x-small"
          icon
          v-bind="props">
          <v-icon :color="props.disabled ? 'white' : '#7E92A2'">{{
            'mdi-chevron-left'
          }}</v-icon>
        </v-btn>
      </template>
      <template #next="props">
        <v-btn
          :style="{
            background: props.disabled ? '#7E92A2' : '#fff',
            border: !props.disabled ? '1px solid #dcdfe2' : null,
          }"
          :variant="props.disabled ? 'tonal' : 'default'"
          size="x-small"
          icon
          v-bind="props">
          <v-icon :color="props.disabled ? 'white' : '#7E92A2'">{{
            'mdi-chevron-right'
          }}</v-icon>
        </v-btn>
      </template>
      <template #item="{ page, isActive, props }">
        <v-btn
          :style="{
            border: !isActive ? '1px solid #dcdfe2' : null,
            color: !isActive ? '#000 !important' : null,
            background: isActive ? '#fff' : null,
          }"
          :variant="isActive ? 'outlined' : 'default'"
          size="x-small"
          icon
          v-bind="props"
          >{{ page }}</v-btn
        >
      </template>
    </v-pagination>
    <div>
      <span>Всього: </span>
      <span>{{ total }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { computed } from 'vue'
export default {
  emits: ['update:model-value'],
  props: {
    modelValue: { type: Number },
    length: { type: Number },
    total: { type: Number },
  },
  setup(props, { emit }) {
    const page = computed({
      get() {
        return props.modelValue
      },
      set(v) {
        emit('update:model-value', v)
      },
    })

    return { page }
  },
}
</script>

<template>
  <div>
    <BSectionLoader
      :loading="
        $loading.isLoading([
          'getPackageDirectory',
          'packageSchemaBody',
          'packageSchemaPersonFull',
        ])
      ">
      <div class="mb-3">Оберіть тип клієнта, щоб заповнити дані:</div>
      <package-directory-select
        v-model="personSchemaCredentials.personType"
        :directory="directory.F082"></package-directory-select>
      <v-checkbox
        v-model="personSchemaCredentials.residence"
        label="Резидент"
        true-value="1"
        false-value="2"></v-checkbox>
    </BSectionLoader>
  </div>
</template>

<script lang="ts">
import { PropType, reactive } from 'vue'
import { Dialog } from 'best-modules/plugins'
import {
  getPackageSchemaBody,
  getPackageSchemaPersonFull,
  createPackage,
} from '@/request/report'
import { PersonFullSchemaCredentials } from '@/utils/types'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { getErrorMessages } from 'best-modules/utils'
import { BSectionLoader } from 'best-modules/components'
import PackageDirectorySelect from './PackageDirectorySelect.vue'
import { usePackageDirectory } from '@/store/package-directory'
import { v4 as uuid } from 'uuid'
import { formatPackageDataRequest } from '@/pages/report/package/formatPackageData'

export default {
  name: 'PackageManually',
  components: { PackageDirectorySelect, BSectionLoader },
  props: {
    dialog: {
      type: Object as PropType<Dialog<'PackageManually'>>,
    },
  },
  methods: { getErrorMessages },
  setup(props) {
    const { directory, fillDirectory } = usePackageDirectory()
    fillDirectory()

    const personSchemaCredentials = reactive<PersonFullSchemaCredentials>({
      personType: '1',
      residence: '1',
    })

    const rules = {
      personType: { required },
      residence: { required },
    }

    const v$ = useVuelidate(rules, personSchemaCredentials)

    const submit = async () => {
      await v$.value.$validate()
      if (!v$.value.$invalid) {
        const packageSchemaBody = await getPackageSchemaBody()
        const packageSchemaPersonFull = await getPackageSchemaPersonFull(
          personSchemaCredentials
        )

        const person_id_full = uuid()
        const liability_id = uuid()
        const loan_id = uuid()

        packageSchemaPersonFull.person_full[0].person_id_full = person_id_full
        packageSchemaPersonFull.person_full[0].f082_person_type =
          personSchemaCredentials.personType
        packageSchemaPersonFull.person_full[0].k030_person_type_residence =
          personSchemaCredentials.residence

        delete packageSchemaBody.contract

        delete packageSchemaBody.collateral

        packageSchemaBody.loan[0].person_id_full = person_id_full
        packageSchemaBody.loan[0].loan_id = loan_id
        packageSchemaBody.loan[0].collateral_id = []
        delete packageSchemaBody.loan[0].tranche

        packageSchemaBody.liability[0].liability_id = liability_id
        packageSchemaBody.liability[0].loan_id = [loan_id]
        packageSchemaBody.liability[0].collateral_id = []
        packageSchemaBody.liability[0].person_id_full = person_id_full
        delete packageSchemaBody.liability[0].tranche

        const packageData = {
          data: [
            {
              data: formatPackageDataRequest({
                ...packageSchemaBody,
                ...packageSchemaPersonFull,
              }),
            },
          ],
          reportId: props.dialog.dialogData.reportId,
        }

        return createPackage({ ...packageData, createType: 'W' })
      }
    }

    return {
      personSchemaCredentials,
      v$,
      submit,
      directory,
    }
  },
}
</script>

<style scoped lang="scss"></style>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 37 38"
    fill="none">
    <mask
      id="mask0_1284_6453"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="-1"
      y="0"
      width="38"
      height="38">
      <rect x="-0.00195312" y="0.96875" width="37" height="37" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_1284_6453)">
      <path
        d="M12.3311 28.7389H24.6644V25.6556H12.3311V28.7389ZM12.3311 22.5723H24.6644V19.4889H12.3311V22.5723ZM9.24776 34.9056C8.39984 34.9056 7.67398 34.6037 7.07016 33.9999C6.46634 33.396 6.16443 32.6702 6.16443 31.8223V7.1556C6.16443 6.30768 6.46634 5.58181 7.07016 4.97799C7.67398 4.37418 8.39984 4.07227 9.24776 4.07227H21.5811L30.8311 13.3223V31.8223C30.8311 32.6702 30.5292 33.396 29.9254 33.9999C29.3215 34.6037 28.5957 34.9056 27.7477 34.9056H9.24776ZM20.0394 14.8639H27.7477L20.0394 7.1556V14.8639Z"
        fill="#FFB200" />
    </g>
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss"></style>

const months = [
  {
    id: 1,
    name: 'Січень',
  },
  {
    id: 2,
    name: 'Лютий',
  },
  {
    id: 3,
    name: 'Березень',
  },
  {
    id: [1, 2, 3],
    name: 'I Квартал',
  },
  {
    id: 4,
    name: 'Квітень',
  },
  {
    id: 5,
    name: 'Травень',
  },
  {
    id: 6,
    name: 'Червень',
  },
  {
    id: [4, 5, 6],
    name: 'II Квартал',
  },
  {
    id: [1, 2, 3, 4, 5, 6],
    name: 'I Півріччя',
  },
  {
    id: 7,
    name: 'Липень',
  },
  {
    id: 8,
    name: 'Серпень',
  },
  {
    id: 9,
    name: 'Вересень',
  },
  {
    id: [7, 8, 9],
    name: 'III Квартал',
  },
  {
    id: 10,
    name: 'Жовтень',
  },
  {
    id: 11,
    name: 'Листопад',
  },
  {
    id: 12,
    name: 'Грудень',
  },
  {
    id: [10, 11, 12],
    name: 'IV Квартал',
  },
  {
    id: [7, 8, 9, 10, 11, 12],
    name: 'I Півріччя',
  },
  {
    id: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    name: 'Рік',
  },
]

const sortMonths = (a, b) => {
  const sortOrder = [
    1,
    2,
    3,
    [1, 2, 3],
    4,
    5,
    6,
    [4, 5, 6],
    [1, 2, 3, 4, 5, 6],
    7,
    8,
    [7, 8, 9],
    9,
    10,
    11,
    12,
    [10, 11, 12],
    [7, 8, 9, 10, 11, 12],
    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  ]

  const getIndex = item =>
    sortOrder.findIndex(
      order => JSON.stringify(order) === JSON.stringify(item.id)
    )

  return getIndex(a) - getIndex(b)
}

function getYearsFrom2024ToCurrent() {
  return Array.from(
    { length: new Date().getFullYear() - 2024 + 1 },
    (_, i) => 2024 + i
  ).map(y => {
    return { year: y }
  })
}

const yearsFrom2024 = getYearsFrom2024ToCurrent()

export { months, sortMonths, yearsFrom2024 }
